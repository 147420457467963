import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function ViewNotesModal({
  show,
  curationNoteOptions,
  // curationNote,
  onHide,
  data,
}) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");

  const [formData, setFormData] = useState({
    considerationJourney: null,
    bhxYes: null,
    changeEdition: null,
  });

  const [curationNote, setCurationNote] = useState(null);

  console.log(data, "data-----");

  console.log(curationNoteOptions, "curationNoteOptions");
  console.log(curationNote, "curationNote");

  useEffect(() => {
    if (data) {
      if (data?.is_curation_note) {
        getCurationNote(data?.userId);
      }
    }
  }, []);

  const getCurationNote = (userId) => {
    setLoading(true);
    fetchData(`api/curation_note/${userId}`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setCurationNote(res);
      } else {
        setCurationNote([]);
        toast.error(res.message);
      }
    });
  };

  // Extract options from API data
  const getOptions = (question) => {
    const questionData = curationNoteOptions.find(
      (item) => item.question === question
    );
    return questionData ? questionData.selected_answers : [];
  };

  const newObject = {
    considerationJourney: "Where are they on their consideration journey?",
    bhxYes: "What will make BHX a definite yes for the user?",
    changeEdition: "How likely are they to change their edition?",
  };

  const createPayload = () => {
    return {
      user: data?.userId,
      curation_data: curationNoteOptions.map((option) => {
        const questionText = option.question;
        const formKey = Object.keys(newObject).find(
          (key) => newObject[key] === questionText
        );

        return {
          curation_question_answer_id: option.id,
          question: questionText,
          selected_answers: formKey ? formData[formKey] : [],
        };
      }),
    };
  };

  const onSubmitNote = async () => {
    setSending(true);
    const payload = createPayload();
    console.log("Payload:", payload);

    fetchData(`api/curation_note/`, "post", payload).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleSelectChange = (field) => (value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            {curationNote && data?.is_curation_note
              ? "View Notes"
              : "Add Notes"}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted">Sales Process</p>

        {data?.is_curation_note ? (
          <>
            {curationNote?.curation_data.map((option) => (
              <div
                className="col-12 mb-5"
                key={option.curation_question_answer_id}
                style={
                  loading ? { filter: "blur(5px)", pointerEvents: "none" } : {}
                }
              >
                <label>
                  <span className="fw-semibold fs-5">{option.question}</span>
                </label>

                <ul>
                  {option?.selected_answers?.map((answer, index) => (
                    <li className="fw-semibold text-gray-600 mt-1" key={index}>
                      {answer}
                    </li>
                  )) || <li>No answers selected</li>}
                </ul>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="row g-9 align-items-center">
              <div className="col-12">
                <label>
                  <span className="required fw-semibold fs-5">
                    {curationNoteOptions[0]?.question}
                  </span>
                </label>

                <small className="form-text text-muted">Ask on the call</small>
                <Select
                  mode="multiple"
                  showSearch
                  name="bhxYes"
                  value={formData.bhxYes}
                  onChange={handleSelectChange("bhxYes")}
                  placeholder="Choose Option"
                  className="fs-7 w-100"
                  style={{ width: "100%" }}
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                >
                  {getOptions(curationNoteOptions[0]?.question).map(
                    (option, index) => (
                      <Select.Option key={index} value={option}>
                        {option}
                      </Select.Option>
                    )
                  )}
                </Select>
              </div>

              <div className="col-12  fv-row mt-5">
                <label>
                  <span className="required fw-semibold fs-5">
                    {curationNoteOptions[1]?.question}
                  </span>
                </label>

                <Select
                  mode="multiple"
                  showSearch
                  name="changeEdition"
                  className="fs-7 w-100"
                  style={{ width: "100%" }}
                  dropdownStyle={{ zIndex: 1100 }}
                  value={formData.changeEdition}
                  onChange={handleSelectChange("changeEdition")}
                  placeholder="Choose an option..."
                  size="large"
                >
                  {getOptions(curationNoteOptions[1]?.question).map(
                    (option, index) => (
                      <Select.Option key={index} value={option}>
                        {option}
                      </Select.Option>
                    )
                  )}
                </Select>
              </div>

              <div className="col-12">
                <label>
                  <span className="required fw-semibold fs-5">
                    {curationNoteOptions[2]?.question}
                  </span>
                </label>

                <Select
                  mode="multiple"
                  showSearch
                  name="considerationJourney"
                  value={formData.considerationJourney}
                  onChange={handleSelectChange("considerationJourney")}
                  placeholder="Choose Option"
                  className="fs-7 w-100"
                  style={{ width: "100%" }}
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                >
                  {getOptions(curationNoteOptions[2]?.question).map(
                    (option, index) => (
                      <Select.Option key={index} value={option}>
                        {option}
                      </Select.Option>
                    )
                  )}
                </Select>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {curationNote && data?.is_curation_note ? (
          <button
            type="button"
            className="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-light-danger fs-7"
              onClick={onHide}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary fs-7"
              onClick={() => {
                toast.loading("Adding Curation Note");
                onSubmitNote();
              }}
              disabled={sending}
            >
              Submit
              {sending && (
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              )}
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
