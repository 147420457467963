import React from "react";

const Booking = () => {
  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Booking</h2>
        </div>

        <div className="card-toolbar"></div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className=" row g-5 mb-0 gx-md-10">
          <div className="col-md-6">
            <div className="mt-0">
              <div className>
                <div className="mb-4 fs-6">
                  <span className="fw-bold">Booking ID:</span> xenan6ye
                </div>
                <div className="mb-4 fs-6">
                  <span className="fw-bold">Start Date:</span> May 9, 2024 1:00
                  pm
                </div>
                <div className="mb-2 fs-6">
                  <span className="fw-bold">End Date:</span> May 14, 2024 1:00
                  pm
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Change Edition
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Link Booking to Application #
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Reset Booking Dates
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Change Payment Status
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold text-danger">
                  Delete Booking
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="mb-8">Status Markers</h4>
            <div className="d-flex flex-stack">
              <div className="fs-6">Call request sent on:</div>
              <div className="fs-6 fw-bolder">Jan 9, 2024</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Call at:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Followup on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Followuped on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Payment link sent on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Paid on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
