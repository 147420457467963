import React, { useState } from "react";

export default function VisaCenter() {
  const [active, setActive] = useState(1);
  return (
    <div>
      <div className="row g-5 g-xxl-5 mb-10 align-items-center">
        <div className="col-xxl-5">
          <div className="d-flex flex-wrap gap-3">
            {/* <div class="position-relative ">
                                          <i
                                              class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
                                          <input type="text" data-kt-table-widget-4="search"
                                              class="form-control text-dark w-250px fs-7 ps-12 all-search"
                                              name="all_search" placeholder="Search Hotel here...">
                                      </div> */}
            <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-175px"
                data-control="select2"
                data-placeholder="Select Edition"
              >
                <option />
                <option value={1}>Prague</option>
                <option value={1}>Goa</option>
                <option value={1}>Amsterdam</option>
              </select>
            </div>
            <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-150px"
                data-control="select2"
                data-placeholder="Select Visa Stage"
              >
                <option />
                <option value={1} />
              </select>
            </div>
            <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-150px"
                data-control="select2"
                data-placeholder="Select Visa Status"
              >
                <option />
                <option value={1} />
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="d-flex gap-3">
            <div className="alert alert-dismissible bg-light-primary border border-primary border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-phone fs-1 text-primary me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Apps</span>
                <h5 className="mb-0 fs-6 text-primary">110</h5>
              </div>
            </div>
            <div className="alert alert-dismissible bg-light-info border border-info border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-document fs-1 text-info me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Visa from BHX</span>
                <h5 className="mb-0 fs-6 text-info">110</h5>
              </div>
            </div>
            <div className="alert alert-dismissible bg-light-success border border-success border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-calendar-tick fs-1 text-success me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Visa not from BHX</span>
                <h5 className="mb-0 fs-6 text-success">110</h5>
              </div>
            </div>
            <div className="alert alert-dismissible bg-light-dark border border-dark border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-abstract-26 fs-1 text-dark me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Upcoming Edition</span>
                <h5 className="mb-0 fs-6 text-dark">110</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end::Row*/}
      <div className="row g-5 g-xxl-5">
        <div className="col-md-12 ">
          {/*begin:::Tabs*/}
          <ul className="nav nav-custom nav-pills fs-4 fw-semibold mb-0">
            {/*begin:::Tab item*/}
            <li className="nav-item">
              <a
                className="nav-link text-gray-800 text-active-light fs-6 fw-bold py-3 active"
                data-bs-toggle="tab"
                href="#kt_tab_1"
              >
                Overview
              </a>
            </li>
            {/*end:::Tab item*/}
            {/*begin:::Tab item*/}
            <li className="nav-item">
              <a
                className="nav-link text-gray-800 text-active-light fs-6 fw-bold py-3 "
                data-bs-toggle="tab"
                href="#kt_tab_2"
              >
                Ongoing
              </a>
            </li>
            {/*end:::Tab item*/}
            {/*begin:::Tab item*/}
            <li className="nav-item">
              <a
                className="nav-link text-gray-800 text-active-light fs-6 fw-bold py-3 "
                data-bs-toggle="tab"
                href="#kt_tab_3"
              >
                Completed
              </a>
            </li>
            {/*end:::Tab item*/}
            {/*begin:::Tab item*/}
            <li className="nav-item">
              <a
                className="nav-link text-gray-800 text-active-light fs-6 fw-bold py-3 "
                data-bs-toggle="tab"
                href="#kt_tab_4"
              >
                Active
              </a>
            </li>
            {/*end:::Tab item*/}
            {/*begin:::Tab item*/}
            <li className="nav-item">
              <a
                className="nav-link text-gray-800 text-active-light fs-6 fw-bold py-3 "
                data-bs-toggle="tab"
                href="#kt_tab_5"
              >
                Cross Check
              </a>
            </li>
            {/*end:::Tab item*/}
          </ul>
          {/*end:::Tabs*/}
        </div>
        <div className="col-md-12">
          {/*begin:::Tab content*/}
          <div className="tab-content" id="myTabContent">
            {/*begin:::Tab pane*/}
            <div
              className="tab-pane fade show active"
              id="kt_tab_1"
              role="tabpanel"
            >
              {/*begin::Card*/}
              <div className="card card-flush mb-6 mb-xl-9">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">Overview</h2>
                  </div>
                  {/*end::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar"></div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body p-9 pt-2">
                  <div className=" row g-5 gx-10">
                    <div className="col-md-6 ">
                      <div className="m-0">
                        <div className="d-flex flex-stack">
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                S1: Appoinment Pending
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fw-bold fs-5 me-3 d-block text-primary">
                                35 Apps
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                S2: Review
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fw-bold fs-5 me-3 d-block text-primary">
                                10 Apps
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                S3: Submitted
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fw-bold fs-5 me-3 d-block text-primary">
                                1 Apps
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                S4: Result
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fw-bold fs-5 me-3 d-block text-primary">
                                9 Apps
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="m-0">
                        <div className="d-flex flex-stack">
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                S5: Not Coming
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fw-bold fs-5 me-3 d-block text-primary">
                                7 Apps
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                S5: Not Sure
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fw-bold fs-5 me-3 d-block text-primary">
                                2 Apps
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                S6: No Update on System
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fw-bold fs-5 me-3 d-block text-primary">
                                124 Apps
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end:::Tab pane*/}
            {/*begin:::Tab pane*/}
            <div className="tab-pane fade " id="kt_tab_2" role="tabpanel">
              {/*begin::Card*/}
              <div className="card card-flush mb-6 mb-xl-9 ">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">59 Visa Entries</h2>
                  </div>
                  {/*end::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar"></div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                {/*begin::Body*/}
                <div className="card-body py-2 pb-7">
                  {/*begin::Table container*/}
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-light-primary">
                          {/* <th class="min-w-75px">Sr. No.</th> */}
                          <th className="min-w-125px">Name</th>
                          <th className="min-w-90px">Days Left</th>
                          <th className="min-w-125px">Edition</th>
                          <th className="min-w-100px">Status</th>
                          <th className="min-w-175px">Visa Stage</th>
                          <th className="min-w-100px">Appoinment</th>
                          <th className="min-w-150px">Visa Status Upd.</th>
                          <th className="min-w-100px">Fee</th>
                          <th className="min-w-100px">Services</th>
                          <th className="min-w-100px">Action</th>
                        </tr>
                      </thead>
                      <tbody className=" fw-semibold fs-7">
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                <img src="assets/media/blank.png" alt="" />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::User details*/}
                              <div className="d-flex flex-column">
                                <a
                                  href
                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                >
                                  Reema Singh
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>183</td>
                          <td>BCX Iceland 7</td>
                          <td>Not Coming</td>
                          <td>
                            <div className>
                              <div className="fw-bold text-primary">
                                S1: Appoinment Pending
                              </div>
                              <span className="fs-7 text-gray-600">
                                Start Process
                              </span>
                            </div>
                          </td>
                          <td>Jan 19, 2024</td>
                          <td>70 Days ago</td>
                          <td>INR 3500</td>
                          <td>
                            <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                              Full Board
                            </span>
                          </td>
                          <td>
                            <div className="d-flex flex-shrink-0 ">
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_visa"
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                              </a>
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_edition_update"
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-file fs-2 text-primary" />
                              </a>
                              <a
                                id="kt_drawer_example_permanent_toggle"
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-airplane fs-2 text-gray-900" />
                              </a>
                              <a
                                id="kt_drawer_example_toggle"
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                              >
                                <i className="ki-outline ki-notepad fs-2 text-gray-900" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table container*/}
                  {/*begin::Separator*/}
                  <div className="separator separator-dashed border-gray-200 mb-n4" />
                  {/*end::Separator*/}
                  {/*begin::Pagination*/}
                  <div className="d-flex flex-stack flex-wrap pt-10">
                    <div className="fs-6 fw-semibold text-gray-700">
                      Showing 1 to 10 of 50 entries
                    </div>
                    {/*begin::Pages*/}
                    <ul className="pagination">
                      <li className="page-item previous">
                        <a href="#" className="page-link">
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="page-item active">
                        <a href="#" className="page-link">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          4
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          5
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          6
                        </a>
                      </li>
                      <li className="page-item next">
                        <a href="#" className="page-link">
                          <i className="next" />
                        </a>
                      </li>
                    </ul>
                    {/*end::Pages*/}
                  </div>
                  {/*end::Pagination*/}
                </div>
                {/*begin::Body*/}
              </div>
            </div>
            {/*end:::Tab pane*/}
            {/*begin:::Tab pane*/}
            <div className="tab-pane fade" id="kt_tab_3" role="tabpanel">
              {/*begin::Card*/}
              <div className="card card-flush mb-6 mb-xl-9 ">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">59 Visa Entries</h2>
                  </div>
                  {/*end::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar"></div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                {/*begin::Body*/}
                <div className="card-body py-2 pb-7">
                  {/*begin::Table container*/}
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-light-primary">
                          {/* <th class="min-w-75px">Sr. No.</th> */}
                          <th className="min-w-125px">Name</th>
                          <th className="min-w-90px">Days Left</th>
                          <th className="min-w-125px">Edition</th>
                          <th className="min-w-100px">Status</th>
                          <th className="min-w-175px">Visa Stage</th>
                          <th className="min-w-100px">Appoinment</th>
                          <th className="min-w-150px">Visa Status Upd.</th>
                          <th className="min-w-100px">Fee</th>
                          <th className="min-w-100px">Services</th>
                          <th className="min-w-100px">Action</th>
                        </tr>
                      </thead>
                      <tbody className=" fw-semibold fs-7">
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                <img src="assets/media/blank.png" alt="" />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::User details*/}
                              <div className="d-flex flex-column">
                                <a
                                  href
                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                >
                                  Reema Singh
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>183</td>
                          <td>BCX Iceland 7</td>
                          <td>Not Coming</td>
                          <td>
                            <div className>
                              <div className="fw-bold text-primary">
                                S1: Appoinment Pending
                              </div>
                              <span className="fs-7 text-gray-600">
                                Start Process
                              </span>
                            </div>
                          </td>
                          <td>Jan 19, 2024</td>
                          <td>70 Days ago</td>
                          <td>INR 3500</td>
                          <td>
                            <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                              Full Board
                            </span>
                          </td>
                          <td>
                            <div className="d-flex flex-shrink-0 ">
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-file fs-2 text-primary" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-airplane fs-2 text-gray-900" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                              >
                                <i className="ki-outline ki-notepad fs-2 text-gray-900" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table container*/}
                  {/*begin::Separator*/}
                  <div className="separator separator-dashed border-gray-200 mb-n4" />
                  {/*end::Separator*/}
                  {/*begin::Pagination*/}
                  <div className="d-flex flex-stack flex-wrap pt-10">
                    <div className="fs-6 fw-semibold text-gray-700">
                      Showing 1 to 10 of 50 entries
                    </div>
                    {/*begin::Pages*/}
                    <ul className="pagination">
                      <li className="page-item previous">
                        <a href="#" className="page-link">
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="page-item active">
                        <a href="#" className="page-link">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          4
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          5
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          6
                        </a>
                      </li>
                      <li className="page-item next">
                        <a href="#" className="page-link">
                          <i className="next" />
                        </a>
                      </li>
                    </ul>
                    {/*end::Pages*/}
                  </div>
                  {/*end::Pagination*/}
                </div>
                {/*begin::Body*/}
              </div>
            </div>
            {/*end:::Tab pane*/}
            {/*begin:::Tab pane*/}
            <div className="tab-pane fade" id="kt_tab_4" role="tabpanel">
              {/*begin::Card*/}
              <div className="card card-flush mb-6 mb-xl-9 ">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">59 Visa Entries</h2>
                  </div>
                  {/*end::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar"></div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                {/*begin::Body*/}
                <div className="card-body py-2 pb-7">
                  {/*begin::Table container*/}
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-light-primary">
                          {/* <th class="min-w-75px">Sr. No.</th> */}
                          <th className="min-w-125px">Name</th>
                          <th className="min-w-90px">Days Left</th>
                          <th className="min-w-125px">Edition</th>
                          <th className="min-w-100px">Status</th>
                          <th className="min-w-175px">Visa Stage</th>
                          <th className="min-w-100px">Appoinment</th>
                          <th className="min-w-150px">Visa Status Upd.</th>
                          <th className="min-w-100px">Fee</th>
                          <th className="min-w-100px">Services</th>
                          <th className="min-w-100px">Action</th>
                        </tr>
                      </thead>
                      <tbody className=" fw-semibold fs-7">
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                <img src="assets/media/blank.png" alt="" />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::User details*/}
                              <div className="d-flex flex-column">
                                <a
                                  href
                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                >
                                  Reema Singh
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>183</td>
                          <td>BCX Iceland 7</td>
                          <td>Not Coming</td>
                          <td>
                            <div className>
                              <div className="fw-bold text-primary">
                                S1: Appoinment Pending
                              </div>
                              <span className="fs-7 text-gray-600">
                                Start Process
                              </span>
                            </div>
                          </td>
                          <td>Jan 19, 2024</td>
                          <td>70 Days ago</td>
                          <td>INR 3500</td>
                          <td>
                            <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                              Full Board
                            </span>
                          </td>
                          <td>
                            <div className="d-flex flex-shrink-0 ">
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-file fs-2 text-primary" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-airplane fs-2 text-gray-900" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                              >
                                <i className="ki-outline ki-notepad fs-2 text-gray-900" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                <img src="assets/media/blank.png" alt="" />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::User details*/}
                              <div className="d-flex flex-column">
                                <a
                                  href
                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                >
                                  Reema Singh
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>183</td>
                          <td>BCX Iceland 7</td>
                          <td>Not Coming</td>
                          <td>
                            <div className>
                              <div className="fw-bold text-primary">
                                S1: Appoinment Pending
                              </div>
                              <span className="fs-7 text-gray-600">
                                Start Process
                              </span>
                            </div>
                          </td>
                          <td>Jan 19, 2024</td>
                          <td>70 Days ago</td>
                          <td>INR 3500</td>
                          <td>
                            <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                              Full Board
                            </span>
                          </td>
                          <td>
                            <div className="d-flex flex-shrink-0 ">
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-file fs-2 text-primary" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              >
                                <i className="ki-outline ki-airplane fs-2 text-gray-900" />
                              </a>
                              <a
                                href="#"
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                              >
                                <i className="ki-outline ki-notepad fs-2 text-gray-900" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table container*/}
                  {/*begin::Separator*/}
                  <div className="separator separator-dashed border-gray-200 mb-n4" />
                  {/*end::Separator*/}
                  {/*begin::Pagination*/}
                  <div className="d-flex flex-stack flex-wrap pt-10">
                    <div className="fs-6 fw-semibold text-gray-700">
                      Showing 1 to 10 of 50 entries
                    </div>
                    {/*begin::Pages*/}
                    <ul className="pagination">
                      <li className="page-item previous">
                        <a href="#" className="page-link">
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="page-item active">
                        <a href="#" className="page-link">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          4
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          5
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          6
                        </a>
                      </li>
                      <li className="page-item next">
                        <a href="#" className="page-link">
                          <i className="next" />
                        </a>
                      </li>
                    </ul>
                    {/*end::Pages*/}
                  </div>
                  {/*end::Pagination*/}
                </div>
                {/*begin::Body*/}
              </div>
            </div>
            {/*end:::Tab pane*/}
            {/*begin:::Tab pane*/}
            <div className="tab-pane fade " id="kt_tab_5" role="tabpanel">
              {/*begin::Card*/}
              <div className="card card-flush mb-6 mb-xl-9 ">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">
                      Total Apps 59 / Total Pending - INR{" "}
                      <span className="text-primary">8169694</span>
                    </h2>
                  </div>
                  {/*end::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar"></div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                {/*begin::Body*/}
                <div className="card-body py-2 pb-7">
                  {/*begin::Table container*/}
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-light-primary">
                          {/* <th class="min-w-75px">Sr. No.</th> */}
                          <th className="min-w-125px">Name</th>
                          <th className="min-w-125px">Edition</th>
                          <th className="min-w-100px">Pending Fee</th>
                          <th className="min-w-150px">Fee Visible to User</th>
                          <th className="min-w-100px">Fee Grant</th>
                          <th className="min-w-100px">Visa Fee</th>
                          <th className="min-w-100px">Payments</th>
                          <th className="min-w-100px">Services</th>
                          <th className="min-w-100px">Status</th>
                        </tr>
                      </thead>
                      <tbody className=" fw-semibold fs-7">
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                <img src="assets/media/blank.png" alt="" />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::User details*/}
                              <div className="d-flex flex-column">
                                <a
                                  href
                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                >
                                  Reema Singh
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>BCX Iceland 7</td>
                          <td>
                            <span className="fw-bolder text-danger">
                              INR 2,66,000
                            </span>
                          </td>
                          <td>
                            <span className="fw-bold">INR 3,05,000</span>
                          </td>
                          <td>
                            <span className="fw-bold">INR 3,05,000</span>
                          </td>
                          <td>
                            <span className="fw-bold">INR 3500</span>
                          </td>
                          <td>
                            <span className="fw-bolder text-primary">
                              INR 39,000
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                              Full Board
                            </span>
                          </td>
                          <td>
                            <div className="d-flex flex-shrink-0 fs-8">
                              Payments made don't match the Edition + Visa fee.
                              Please cross check.
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                <img src="assets/media/blank.png" alt="" />
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::User details*/}
                              <div className="d-flex flex-column">
                                <a
                                  href
                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                >
                                  Reema Singh
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>BCX Iceland 7</td>
                          <td>
                            <span className="fw-bolder text-danger">
                              INR 2,66,000
                            </span>
                          </td>
                          <td>
                            <span className="fw-bold">INR 3,05,000</span>
                          </td>
                          <td>
                            <span className="fw-bold">INR 3,05,000</span>
                          </td>
                          <td>
                            <span className="fw-bold">INR 3500</span>
                          </td>
                          <td>
                            <span className="fw-bolder text-primary">
                              INR 39,000
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-light-success flex-shrink-0 align-self-center py-3 px-4 fs-7">
                              Full Board
                            </span>
                          </td>
                          <td>
                            <div className="d-flex flex-shrink-0 fs-8">
                              Payments made don't match the Edition + Visa fee.
                              Please cross check.
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table container*/}
                  {/*begin::Separator*/}
                  <div className="separator separator-dashed border-gray-200 mb-n4" />
                  {/*end::Separator*/}
                  {/*begin::Pagination*/}
                  <div className="d-flex flex-stack flex-wrap pt-10">
                    <div className="fs-6 fw-semibold text-gray-700">
                      Showing 1 to 10 of 50 entries
                    </div>
                    {/*begin::Pages*/}
                    <ul className="pagination">
                      <li className="page-item previous">
                        <a href="#" className="page-link">
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="page-item active">
                        <a href="#" className="page-link">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          4
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          5
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="#" className="page-link">
                          6
                        </a>
                      </li>
                      <li className="page-item next">
                        <a href="#" className="page-link">
                          <i className="next" />
                        </a>
                      </li>
                    </ul>
                    {/*end::Pages*/}
                  </div>
                  {/*end::Pagination*/}
                </div>
                {/*begin::Body*/}
              </div>
            </div>
            {/*end:::Tab pane*/}
          </div>
        </div>
      </div>
    </div>
  );
}
