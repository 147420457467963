import moment from "moment";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Table from "./Table";
import { LIMITS, routesList } from "../../../config";
import { Link } from "react-router-dom";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Loader, Pagination } from "../../../components";
import { Select } from "antd";

const dataOld = [
  {
    id: 1,
    edition: "BHX Prague & Berlin 4",
    image: "/media/img.jpg",
    date: "Apr 19, 2024 2:00 PM",
    vibeScore: 4,
    sold: 15,
    conversion: "6.0%",
    payments: 5,
    preferences: 16,
    avgAge: 37,
    price: "₹1,35,000",
    advance: "Advance 20",
    maxSpots: 24,
    ratio: "0:0",
    m: 7,
    f: 7,
    o: 7,
    isRedEdition: false,
  },
  {
    id: 2,
    edition: "BHX Prague & Berlin 4",
    image: "/media/img.jpg",
    date: "Apr 19, 2024 2:00 PM",
    vibeScore: 4,
    sold: 15,
    conversion: "6.0%",
    payments: 5,
    preferences: 16,
    avgAge: 37,
    price: "₹1,35,000",
    advance: "Advance 20",
    maxSpots: 24,
    ratio: "0:0",
    m: 7,
    f: 7,
    o: 7,
    isRedEdition: true, // This edition is marked in red
  },
  {
    id: 3,
    edition: "BHX Prague & Berlin 4",
    image: "/media/img.jpg",
    date: "Apr 19, 2024 2:00 PM",
    vibeScore: 4,
    sold: 15,
    conversion: "6.0%",
    payments: 5,
    preferences: 16,
    avgAge: 37,
    price: "₹1,35,000",
    advance: "Advance 20",
    maxSpots: 24,
    ratio: "0:0",
    m: 7,
    f: 7,
    o: 7,
    isRedEdition: false,
  },
];

export default function ManageEditions() {
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [editionsStatusOptions, setEditionsStatusOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState({
    project: null,
    status: null,
  });
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getEditionsStatus();
    getProjectOptions();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, filterData, debouncedSearchTerm]);

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/editions/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&search=${encodeURIComponent(debouncedSearchTerm || "")}&status=${
        filterData?.status || ""
      }&project=${filterData?.project || ""}`
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const getEditionsStatus = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/editions/editions-choices/`).then((res) => {
      if (res.success) {
        const data = res["edition_status_choices"]?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });
        setEditionsStatusOptions(data);
      } else {
        setEditionsStatusOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getProjectOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectOptions(data);
      } else {
        setProjectOptions([]);
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
        {/*begin::Col*/}
        <div className="col-xl-2 ">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#F1416C",
              backgroundImage: 'url("/media/wave-bg-light.svg")',
            }}
          >
            {/*begin::Header*/}
            <div className="card-header pt-5 mb-3  justify-content-center">
              {/*begin::Icon*/}
              <div
                className="d-flex flex-center rounded-circle h-80px w-80px"
                style={{
                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                  backgroundColor: "#F1416C",
                }}
              >
                <i className="ki-outline ki-call text-white fs-2qx lh-0" />
              </div>
              {/*end::Icon*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end mb-3 px-2 justify-content-center">
              {/*begin::Info*/}
              <div className=" text-center">
                <span className="fs-2hx text-white fw-bold me-0 text-end">
                  323
                </span>
                <div className="fw-bold fs-6 text-white">
                  <span className="d-block">Total No. Of Edition</span>
                </div>
              </div>
              {/*end::Info*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-2 ">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#7843e6",
              backgroundImage: 'url("/media/wave-bg-light.svg")',
            }}
          >
            {/*begin::Header*/}
            <div className="card-header pt-5 mb-3  justify-content-center">
              {/*begin::Icon*/}
              <div
                className="d-flex flex-center rounded-circle h-80px w-80px"
                style={{
                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                  backgroundColor: "#7843e6",
                }}
              >
                <i className="ki-outline ki-tech-wifi text-white fs-2qx lh-0" />
              </div>
              {/*end::Icon*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end mb-3 px-2 justify-content-center">
              {/*begin::Info*/}
              <div className=" text-center">
                <span className="fs-2hx text-white fw-bold me-0 text-end">
                  275
                </span>
                <div className="fw-bold fs-6 text-white">
                  <span className="d-block">Live Edition</span>
                </div>
              </div>
              {/*end::Info*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-2 ">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#72ad32",
              backgroundImage: 'url("/media/wave-bg-light.svg")',
            }}
          >
            {/*begin::Header*/}
            <div className="card-header pt-5 mb-3  justify-content-center">
              {/*begin::Icon*/}
              <div
                className="d-flex flex-center rounded-circle h-80px w-80px"
                style={{
                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                  backgroundColor: "#72ad32",
                }}
              >
                <i className="ki-outline ki-watch text-white fs-2qx lh-0" />
              </div>
              {/*end::Icon*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end mb-3 px-2 justify-content-center">
              {/*begin::Info*/}
              <div className=" text-center">
                <span className="fs-2hx text-white fw-bold me-0 text-end">
                  112
                </span>
                <div className="fw-bold fs-6 text-white">
                  <span className="d-block">Upcoming Edition</span>
                </div>
              </div>
              {/*end::Info*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-6">
          {/*begin::Chart widget 36*/}
          <div className="card card-flush overflow-hidden h-lg-100">
            {/*begin::Header*/}
            <div className="card-header pt-4  min-h-30px">
              <h3 className="card-title mb-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-900">
                  Top 5 Edition
                </span>
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body  py-0 align-items-end p-0 ps-5 pe-10">
              <div>
                {/* <h1>chart</h1> */}
                <Chart
                  options={{
                    chart: {
                      fontFamily: "inherit",
                      type: "bar",
                      height: 180,
                      toolbar: { show: !1 },
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 4,
                        horizontal: !0,
                        distributed: !0,
                        barHeight: 19,
                        dataLabels: { position: "bottom" },
                      },
                    },
                    stroke: { show: !0, width: 3, colors: ["transparent"] },
                    dataLabels: {
                      enabled: !1,
                      textAnchor: "start",
                      offsetX: 0,
                      // formatter: function (e, t) {
                      //   e *= 1e3;
                      //   return wNumb({ thousand: "," }).to(e);
                      // },
                      style: {
                        fontSize: "10px",
                        fontWeight: "600",
                        align: "left",
                      },
                    },
                    legend: { show: !1 },
                    colors: ["#7843e6", "#b794ff", "#cdb5ff", "#a7a2f0"],
                    xaxis: {
                      categories: [
                        "GOA",
                        "Shillong",
                        "Leh",
                        "Sri Lanka",
                        "Bali",
                      ],
                      labels: {
                        formatter: function (e) {
                          return e + "";
                        },
                        style: {
                          colors: "#252F4A",
                          fontSize: "12px",
                          fontWeight: "600",
                          align: "left",
                        },
                      },
                      axisBorder: { show: !1 },
                    },
                    yaxis: {
                      labels: {
                        formatter: function (e, t) {
                          return Number.isInteger(e)
                            ? e +
                                " - " +
                                parseInt((100 * e) / 18).toString() +
                                "%"
                            : e;
                        },
                        style: {
                          colors: "#252F4A",
                          fontSize: "12.5px",
                          fontWeight: "700",
                        },
                        offsetY: 3,
                        align: "right",
                      },
                    },
                    grid: {
                      borderColor: "#DBDFE9",
                      xaxis: { lines: { show: !0 } },
                      yaxis: { lines: { show: !1 } },
                      strokeDashArray: 2,
                    },
                    tooltip: {
                      style: { fontSize: "12px" },
                      y: {
                        formatter: function (e) {
                          return e;
                        },
                      },
                    },
                  }}
                  series={[{ name: "", data: [12, 7, 6, 9, 2] }]}
                  type="bar"
                  // width={"100%"}
                  height={200}
                />
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Chart widget 36*/}
        </div>
        {/*end::Col*/}
      </div>
      {/*begin::Row*/}
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12 ">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search edition here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="position-relative mr-4"></div>
              </div>
              <div className="card-toolbar">
                <div className="position-relative me-3 ">
                  <Select
                    showSearch
                    name="project"
                    value={filterData?.project}
                    onChange={(value) => {
                      setFilterData((prevState) => ({
                        ...prevState,
                        project: value,
                      }));
                    }}
                    placeholder="Select Project"
                    className="fs-7 w-150px"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={projectOptions}
                    allowClear
                    onClear={() =>
                      setFilterData((prevState) => ({
                        ...prevState,
                        project: null,
                      }))
                    }
                  ></Select>
                </div>
                <Select
                  showSearch
                  name="status"
                  value={filterData?.status}
                  onChange={(value) => {
                    setFilterData((prevState) => ({
                      ...prevState,
                      status: value,
                    }));
                  }}
                  placeholder="Select Status"
                  className="fs-8 me-3 w-150px"
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                  options={editionsStatusOptions}
                  allowClear
                  onClear={() =>
                    setFilterData((prevState) => ({
                      ...prevState,
                      status: null,
                    }))
                  }
                ></Select>
                {/* <a class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_edition"><i class="ki-outline ki-plus-square fs-2"></i>Add Edition</a> */}
                <Link
                  to={routesList["handle_editions"].path}
                  className="btn btn-primary fs-7 fw-bold"
                >
                  <i className="ki-outline ki-plus-square fs-2" /> Add Edition
                </Link>
                {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add New User</a> */}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      {/*begin::Table container*/}
                      <Table data={data} />

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>
      {/*end::Row*/}
    </div>
  );
}
