import React from "react";

export default function ChangeEdition() {
  return (
    <div className="row g-5 g-xxl-10">
      <div className="col-xxl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header">
            <div className="d-flex flex-stack flex-wrap gap-4">
              <div className="position-relative ">
                {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                <div
                  data-kt-daterangepicker="true"
                  data-kt-daterangepicker-opens="left"
                  className="btn btn-sm btn-white border border-gray-300 d-flex align-items-center px-4 rounded"
                >
                  {/*begin::Display range*/}
                  <div className="text-gray-600 fw-bold">
                    Loading date range...
                  </div>
                  {/*end::Display range*/}
                  <i className="ki-outline ki-calendar-8 fs-1 ms-2 me-0" />
                </div>
              </div>
              {/*end::Daterangepicker*/}
              {/* <div class="position-relative ">
                                                  <i
                                                      class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
                                                  <input type="text" data-kt-table-widget-4="search"
                                                      class="form-control text-dark w-250px fs-7 ps-12 all-search"
                                                      name="all_search" placeholder="Search Hotel here...">
                                              </div> */}
              <div className="position-relative ">
                <select
                  className="form-select fs-7 min-w-175px"
                  data-control="select2"
                  data-placeholder="Select Project"
                >
                  <option />
                  <option value={1}>Demo Project</option>
                </select>
              </div>
              <div className="position-relative ">
                <select
                  className="form-select fs-7 min-w-150px"
                  data-control="select2"
                  data-placeholder="Select Status"
                >
                  <option />
                  <option value={1}>Paid</option>
                </select>
              </div>
              <div className="position-relative ">
                <select
                  className="form-select fs-7 min-w-175px"
                  data-control="select2"
                  data-placeholder="Moved From"
                >
                  <option />
                  <optgroup label="Editions">
                    <option value={1}>BHX</option>
                  </optgroup>
                </select>
              </div>
              <div className="position-relative ">
                <select
                  className="form-select fs-7 min-w-175px"
                  data-control="select2"
                  data-placeholder="Moved To"
                >
                  <option />
                  <optgroup label="Editions">
                    <option value={1}>BHX</option>
                  </optgroup>
                </select>
              </div>
            </div>
            <div className="card-toolbar"></div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 pb-7">
            {/*begin::Table container*/}
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">User</th>
                    <th className="min-w-70px">Days</th>
                    <th className="min-w-75px">Paid On</th>
                    <th className="min-w-100px">Edition Changed on</th>
                    <th className="min-w-100px">Old Edition</th>
                    <th className="min-w-100px">New Edition</th>
                    <th className="min-w-75px">Moved By</th>
                    <th className="min-w-75px">Status</th>
                    <th className="min-w-50px">Action</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                          <img src="/media/blank.png" alt="" />
                        </div>
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-125px"
                          >
                            Jeeban Rath
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>243</td>
                    <td>11 Feb 2024</td>
                    <td>
                      <span className="text-orange fw-bold">20 Feb 2024</span>
                    </td>
                    <td>BCX Iceland 7</td>
                    <td>
                      <span className="text-primary fw-bolder">
                        BCX Japan 5
                      </span>
                    </td>
                    <td>Jeeban</td>
                    <td>
                      <span className="badge py-3 px-4 fs-7 badge-light-primary">
                        Paid
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-shrink-0 ">
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-pencil fs-2 text-dark" />
                        </a>
                        {/* <a href="#" class="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                                                      <i class="lab la-linkedin fs-2x text-primary"></i>
                                                                      <i class="ki-outline ki-trash fs-2 text-danger"></i> 
                                                                  </a> */}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                          <img src="/media/blank.png" alt="" />
                        </div>
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-125px"
                          >
                            Shalini Nambu
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>243</td>
                    <td>11 Feb 2024</td>
                    <td>
                      <span className="text-orange fw-bold">20 Feb 2024</span>
                    </td>
                    <td>BCX Iceland 7</td>
                    <td>
                      <span className="text-primary fw-bolder">
                        BCX Japan 5
                      </span>
                    </td>
                    <td>Jeeban</td>
                    <td>
                      <span className="badge py-3 px-4 fs-7 badge-light-primary">
                        Paid
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-shrink-0 ">
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-pencil fs-2 text-dark" />
                        </a>
                        {/* <a href="#" class="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                                                      <i class="lab la-linkedin fs-2x text-primary"></i>
                                                                      <i class="ki-outline ki-trash fs-2 text-danger"></i> 
                                                                  </a> */}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                          <img src="/media/blank.png" alt="" />
                        </div>
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-125px"
                          >
                            John Doe
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>243</td>
                    <td>11 Feb 2024</td>
                    <td>
                      <span className="text-orange fw-bold">20 Feb 2024</span>
                    </td>
                    <td>BCX Iceland 7</td>
                    <td>
                      <span className="text-primary fw-bolder">
                        BCX Japan 5
                      </span>
                    </td>
                    <td>Jeeban</td>
                    <td>
                      <span className="badge py-3 px-4 fs-7 badge-light-primary">
                        Paid
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-shrink-0 ">
                        <a
                          href="#"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-pencil fs-2 text-dark" />
                        </a>
                        {/* <a href="#" class="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                                                      <i class="lab la-linkedin fs-2x text-primary"></i>
                                                                      <i class="ki-outline ki-trash fs-2 text-danger"></i> 
                                                                  </a> */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            {/*begin::Separator*/}
            <div className="separator separator-dashed border-gray-200 mb-n4" />
            {/*end::Separator*/}
            {/*begin::Pagination*/}
            <div className="d-flex flex-stack flex-wrap pt-10">
              <div className="fs-7 fw-bold text-gray-700 Showing d-flex align-items-center">
                <select
                  name
                  aria-controls
                  className="form-select form-select-sm form-select-solid text-center fw-bolder w-75px me-3"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                Showing 1 to 2 of 50 entries
              </div>
              {/*begin::Pages*/}
              <ul className="pagination">
                <li className="page-item previous">
                  <a href="#" className="page-link">
                    <i className="previous" />
                  </a>
                </li>
                <li className="page-item active">
                  <a href="#" className="page-link">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    5
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    6
                  </a>
                </li>
                <li className="page-item next">
                  <a href="#" className="page-link">
                    <i className="next" />
                  </a>
                </li>
              </ul>
              {/*end::Pages*/}
            </div>
            {/*end::Pagination*/}
          </div>
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>
    </div>
  );
}
