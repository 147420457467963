import React from "react";
import { Loader, Pagination } from "../../../components";

const paymentTableData = [
  {
    name: "Alisha Mangla",
    date: "2024-01-15",
    duration: "4.0 months",
    amount: "₹98000",
    destination: "BHK Bart 6",
    batch: "6/17",
    batchName: "BHK Alisha",
    rating: 3.4,
    pendingAmount: "₹70000",
    place: "Ahmedabad",
  },
  {
    name: "Rohan Sharma",
    date: "2024-02-10",
    duration: "3.5 months",
    amount: "₹75000",
    destination: "BHK Bart 4",
    batch: "5/15",
    batchName: "BHK Rohan",
    rating: 4.0,
    pendingAmount: "₹35000",
    place: "Mumbai",
  },
  {
    name: "Sneha Verma",
    date: "2024-03-05",
    duration: "5.0 months",
    amount: "₹105000",
    destination: "BHK Bart 3",
    batch: "7/18",
    batchName: "BHK Sneha",
    rating: 5,
    pendingAmount: "₹25000",
    place: "Bangalore",
  },
];

const refundTableData = [
  {
    name: "Prarthana",
    reason: "Cannot join in for the edition, too expensive",
    amount: 15000,
    emailPhone: "abc.1234@gmail.com",
    date: "Feb 9, 2024",
  },
  {
    name: "Prarthana 33",
    reason: "Cannot join in for the edition, too expensive",
    amount: 35000,
    emailPhone: "cde.1234@gmail.com",
    date: "Feb 29, 2024",
  },
];

const salesInsightData = [
  {
    name: "Shashank",
    appConfirm: "199+0.3",
    appContribution: 0,
    insideConfirm: "199+0.3",
    emailsSent: 3,
    callDonePlusBooked: "",
    edition: "BCK Future Editions",
    paymentDate: "",
  },
];

const singleRoomAddonsData = [
  {
    name: "NikitaInVicky's",
    refundedOn: "BHK Sri Lanka 4",
    amount: "INR 35000",
    emailPhone: "vikkys.nikkaita99@gmail.com",
    date: "Sep 23, 2023",
  },
];

export default function Payments() {
  return (
    <div>
      <div className="row gy-5 g-xl-5">
        {/* table 1 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Payment Details</h3>
              <div className="card-toolbar gap-2">
                <div>
                  <select
                    className="form-select"
                    aria-label="Select Payment Method"
                  >
                    <option value="" disabled selected>
                      Select value
                    </option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-200px">Name</th>
                          <th className="min-w-120px">Duration</th>
                          <th className="min-w-105px">Amount</th>
                          <th className="min-w-135px">Destination</th>
                          <th className="min-w-60px">Batch</th>
                          <th className="min-w-105px">Batch Name</th>
                          <th className="min-w-60px">Rating</th>
                          <th className="min-w-70px">Pending Amount</th>
                          <th className="min-w-7px">Place</th>
                        </tr>
                      </thead>

                      <tbody>
                        {paymentTableData?.length > 0
                          ? paymentTableData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-circle symbol-45px overflow-hidden me-3">
                                        {item?.photo ? (
                                          <img src={item?.photo} alt="" />
                                        ) : (
                                          <div class="symbol symbol-circle symbol-45px mr-3">
                                            <span
                                              class="symbol-label fs-2 text-primary bg-light-primary"
                                              style={{
                                                border: "1px dashed #7843e6",
                                                borderRadius: "50%",
                                              }}
                                            >
                                              {item?.name
                                                ?.charAt(0)
                                                .toUpperCase()}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column">
                                        <a
                                          href
                                          className="text-primary mb-1 fw-bolder gotham_black"
                                        >
                                          {item?.name}
                                        </a>
                                        <span className="text-gray-600 fw-semibold">
                                          {item?.date}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="fw-bold fs-6 ">
                                    {item.duration}
                                  </td>
                                  <td className="fw-semibold fs-6">
                                    {item.amount}
                                  </td>
                                  <td>{item.destination}</td>
                                  <td>{item.batch}</td>
                                  <td>{item.batchName}</td>
                                  <td>
                                    <div class="symbol symbol-40px">
                                      <div
                                        class="symbol-label fs-5 fw-bold text-primary"
                                        style={{
                                          border: "2px solid #7843e6",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        {item.rating}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="fw-semibold fs-6">
                                    {item.pendingAmount}
                                  </td>
                                  <td>
                                    <div>
                                      <span className="badge badge-primary fs-6">
                                        {item.place}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={paymentTableData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* table 2 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Refund Requests</h3>
              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">Reason</th>
                          <th className="min-w-125px">Amount</th>
                          <th className="min-w-125px">Email/Phone</th>
                          <th className="min-w-100px">Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {refundTableData?.length > 0
                          ? refundTableData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.reason}</td>
                                  <td className="fw-semibold fs-6">
                                    {item.amount}
                                  </td>
                                  <td>{item.emailPhone}</td>
                                  <td>{item.date}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={refundTableData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* table 3 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Sales Insights </h3>
              <div className="card-toolbar gap-2">
                <div></div>
              </div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">App + Confirm</th>
                          <th className="min-w-125px">App + Contribution</th>
                          <th className="min-w-125px">Inside + Confirm</th>
                          <th className="min-w-100px">Emails Sent</th>
                          <th className="min-w-175px">Call Done + Booked</th>
                          <th className="min-w-90px">Edition</th>
                          <th className="min-w-90px">Payment Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {salesInsightData?.length > 0
                          ? salesInsightData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.appConfirm}</td>
                                  <td>{item.appContribution}</td>
                                  <td>{item.insideConfirm}</td>
                                  <td>{item.emailsSent}</td>
                                  <td>{item.callDonePlusBooked}</td>
                                  <td>{item.edition}</td>
                                  <td>{item.paymentDate}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={salesInsightData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* table 4 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Single Room Add-ons</h3>

              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">Refunded On</th>
                          <th className="min-w-125px">Amount</th>
                          <th className="min-w-125px">Email/Phone</th>
                          <th className="min-w-100px">Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {singleRoomAddonsData?.length > 0
                          ? singleRoomAddonsData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.refundedOn}</td>
                                  <td>{item.amount}</td>
                                  <td>{item.emailPhone}</td>
                                  <td>{item.date}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={singleRoomAddonsData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
