import React, { useEffect, useState } from "react";
import { LIMITS, routesList } from "../../../../config";
import { Link, useParams } from "react-router-dom";
import { Upload, Button, message, Select, DatePicker, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { validateFields } from "../../../../components/validation";
import dayjs from "dayjs";
import { StatusModal } from "../../../customers/modals";

export default function BookingDetail() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [statusList, setStatusList] = useState([]);

  const [formData, setFormData] = useState({
    uuid: "",
    user: "",
    email: "",
    score: "",
    status: "",
    status_value: null,
    fieldOfWork: null,
    category: null,
    annualRevenue: null,
    identity: null,
    dateOfBirth: "",
    reasonsToAttend: "",
    areasOfGrowth: "",
    designation: "",
    story: "",
    instagramProfile: "",
    linkedinProfile: "",
    companyWebsite: "",
    hasReferral: false,
    referralName: "",
    intentions: "",
  });

  const [fieldOfWorkOptions, setFieldOfWorkOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [annualRevenueOptions, setAnnualRevenueOptions] = useState([]);
  const [addOnsList, setAddOnsList] = useState([]);
  const [travelStyleList, setTravelList] = useState([]);
  const [regions, setRegions] = useState([]);

  const [bookingDetailData, setBookingDetailData] = useState([]);
  const [bookingId, setBookingId] = useState(null);

  useEffect(() => {
    if (id) {
      getBookingDetailData();
      getStatus();
    }
  }, [id]);

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  //   useEffect(() => {
  //     getFieldOfWork();
  //     getCategory();
  //     getAnnualRevenue();
  //   }, []);

  const clearInputs = () => {
    setFormData({
      uuid: "",
      user: "",
      email: "",
      score: "",
      status: "",
      status_value: null,
      fieldOfWork: null,
      category: null,
      annualRevenue: null,
      identity: null,
      dateOfBirth: "",
      reasonsToAttend: "",
      areasOfGrowth: "",
      designation: "",
      story: "",
      instagramProfile: "",
      linkedinProfile: "",
      companyWebsite: "",
      hasReferral: false,
      referralName: "",
      intentions: "",
    });
  };

  const getBookingDetailData = () => {
    fetchData(`api/booking/${id}`).then((res) => {
      if (res.success) {
        const data = res;
        const user = data.user;

        const mappedData = {
          uuid: user.uuid || "",
          user: `${user.first_name} ${user.last_name}`,
          email: user.email || "",
          score: user.score || "",
          status: data.status_labels || "",
          status_value: data.status,
          fieldOfWork: user.user_work[0]?.field_work_label || null,
          category: user.user_work[0]?.category_label || null,
          annualRevenue: user.user_work[0]?.revenue_label || null,
          identity: user.user_profile.identify_label || null,
          dateOfBirth: user.date_of_birth || "",
          reasonsToAttend:
            data.top_priority_labels.length > 0
              ? data.top_priority_labels.join(", ")
              : "",
          areasOfGrowth:
            data.growth_areas_labels.length > 0
              ? data.growth_areas_labels.join(", ")
              : "",
          designation: user.user_work[0]?.designation || "",
          story: user.user_profile.bio || "",
          instagramProfile: user.user_profile.instagram_url || "",
          linkedinProfile: user.user_profile.linkedin_url || "",
          companyWebsite: user.user_work[0]?.work_url || "",
          hasReferral: false,
          referralName: "",
          intentions: data?.intentions,
        };

        setFormData(mappedData);
      } else {
        clearInputs();
        toast.error(res.message);
      }
    });
  };

  const getFieldOfWork = () => {
    setLoading(true);

    fetchData(`api/itinerary/?edition=${id || bookingId}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setFieldOfWorkOptions(res.data);
      } else {
        setFieldOfWorkOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getCategory = () => {
    fetchData(`api/hotel/hotel-names`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setCategoryOptions(data);
      } else {
        setCategoryOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getAnnualRevenue = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setAnnualRevenueOptions(data);
      } else {
        setAnnualRevenueOptions([]);
        toast.error(res.message);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validationRules = {
    fieldOfWork: { required: true },
    category: { required: true },
    annualRevenue: { required: true },
    identity: { required: true },
    dateOfBirth: { required: true, type: "date" },
    reasonsToAttend: { required: true, maxLength: 500 },
    areasOfGrowth: { required: true, maxLength: 500 },
    designation: { required: true, maxLength: 100 },
    story: { required: true, maxLength: 1000 },
    instagramProfile: { type: "url" },
    linkedinProfile: { type: "url" },
    companyWebsite: { type: "url" },
    hasReferral: { type: "boolean" },
    referralName: {
      required: (formData) => formData.hasReferral,
      maxLength: 100,
    },
  };

  const handleSubmit = () => {
    const { isValid, errors } = validateFields(formData, validationRules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Adding Booking Request...");
    }

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      fieldOfWork: formData.fieldOfWork,
      category: formData.category,
      annualRevenue: formData.annualRevenue,
      identity: formData.identity,
      dateOfBirth: formData.dateOfBirth,
      reasonsToAttend: formData.reasonsToAttend,
      areasOfGrowth: formData.areasOfGrowth,
      designation: formData.designation,
      story: formData.story,
      instagramProfile: formData.instagramProfile,
      linkedinProfile: formData.linkedinProfile,
      companyWebsite: formData.companyWebsite,
      hasReferral: formData.hasReferral,
      referralName: formData.hasReferral ? formData.referralName : undefined, // Only include if hasReferral is true
    };

    console.log(body, "body");

    let api = id ? `api/bookings/${id}/` : "api/bookings/";
    let method = id ? "put" : "post";

    // fetchData(api, method, body,).then((res) => {
    //   toast.remove();
    //   setSending(false);
    //   if (res.success) {
    //     setEditionId(res?.id);

    //     if (id) {
    //       toast.success("Success");
    //     } else {
    //       setEditionId(res?.id);
    //       clearInputs();

    //       toast.success("Please Add Itinerary To Complete Edition");
    //     }
    //   } else {
    //     toast.error(res.message);
    //   }
    // });
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fieldOfWorkOptions1 = [
    { value: "tech", label: "Technology" },
    { value: "finance", label: "Finance" },
    // Add more options as needed
  ];

  const categoryOptions1 = [
    { value: "software", label: "Software Development" },
    { value: "design", label: "Design" },
    // Add more options as needed
  ];

  const annualRevenueOptions1 = [
    { value: "0-50k", label: "$0 - $50,000" },
    { value: "50k-100k", label: "$50,000 - $100,000" },
    // Add more options as needed
  ];

  const identityOptions1 = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "pending", label: "Pending" },
    { value: "inactive", label: "Inactive" },
  ];

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className={`${id ? "col-xxl-12" : "col-xxl-12"}`}>
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header ">
              <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center">
                <Link
                  to={routesList["bookings"].path}
                  className="d-flex me-3 btn btn-sm btn-icon btn-light-primary"
                >
                  <i className="ki-outline ki-arrow-left fs-2 " />
                </Link>
                Booking Details
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pe-0">
              {/* <div className="scroll-y h-700px pe-5"> */}
              <div className="h-720px pe-5">
                <div className="row g-5 mb-5 align-items-center">
                  {/* New row for user, email, score, and status */}
                  <div className="row g-5 align-items-center">
                    <div className="row align-items-center">
                      <label className="fw-bold fs-2 mb-2">
                        Personal Details
                      </label>
                    </div>

                    <div className="col-md-3 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        User
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.user || "-"}
                      </p>
                    </div>
                    <div className="col-md-3 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Email
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.email || "-"}
                      </p>
                    </div>
                    <div className="col-md-2 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Score
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        <span className="badge badge-primary">
                          {formData.score || "-"}
                        </span>
                      </p>
                    </div>

                    <div
                      className="col-md-2 fv-row mb-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsOpenUserModal(true);
                      }}
                    >
                      <Tooltip title="Click To Change Status">
                        <label className="fs-6 text-gray-600 fw-semibold mb-2">
                          Status
                        </label>
                        <p className="fs-5 text-gray-800 fw-bold">
                          <span
                            className={`badge badge-light-${
                              formData?.status_value == 1
                                ? "warning"
                                : formData?.status_value == 2
                                ? "danger"
                                : formData?.status_value == 3
                                ? "success"
                                : "dark"
                            }`}
                          >
                            {formData.status || "-"}
                          </span>
                        </p>
                      </Tooltip>
                    </div>

                    <div className="col-md-2 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Date of Birth
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.dateOfBirth || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="row g-5 align-items-center">
                    <div className="row align-items-center">
                      <label className="fw-bold fs-2 mb-2">Work Details</label>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick your field of work
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.fieldOfWork || "-"}
                      </p>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick a relevant category
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.category || "-"}
                      </p>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick your annual revenue
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.annualRevenue || "-"}
                      </p>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick your annual revenue
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.annualRevenue || "-"}
                      </p>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        You identify as
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.identity || "-"}
                      </p>
                    </div>
                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Designation
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.designation || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Top 2 reasons to attend this experience
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.reasonsToAttend || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Top 2 areas of growth
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.areasOfGrowth || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row ">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Tell us your story to attend this adventure
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.story || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row ">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Tell us your intention to attend this adventure
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.intentions || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="row g-5 align-items-center">
                    <div className="row g-5 align-items-center">
                      <label className="fw-bold fs-2 mb-2">Socials</label>
                    </div>

                    {/* <div className="col-md-4 fv-row mb-5">
                    <label className="fs-6 text-gray-600 fw-semibold mb-2">
                      Instagram profile
                    </label>
                    <p className="fs-5 text-gray-800 fw-bold">
                      {formData.instagramProfile}
                    </p>
                  </div>

                  <div className="col-md-4 fv-row mb-5">
                    <label className="fs-6 text-gray-600 fw-semibold mb-2">
                      LinkedIn profile
                    </label>
                    <p className="fs-5 text-gray-800 fw-bold">
                      {formData.linkedinProfile}
                    </p>
                  </div> */}

                    <div className="col-md-12 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Company website / portfolio
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.companyWebsite || "-"}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="row g-5 mb-5 align-items-center">
                  <div className="col-md-12 fv-row mb-5">
                    <label className="fs-6 text-gray-600 fw-semibold mb-2">
                      I have a referral from a tribe member
                    </label>
                    <p className="fs-6">
                      {formData.hasReferral ? "Yes" : "No"}
                    </p>
                  </div>
                </div>

                {formData.hasReferral && (
                  <div className="row g-5 mb-5 align-items-center">
                    <div className="col-md-12 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Enter their full name for faster access
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData.referralName}
                      </p>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      {isOpenUserModal && (
        <StatusModal
          show={isOpenUserModal}
          statusList={statusList}
          onHide={() => {
            setIsOpenUserModal(false);
            setLoading(true);
            getBookingDetailData();
          }}
          data={{
            uuid: id,
            status: formData?.status_value,
          }}
        />
      )}
    </div>
  );
}
