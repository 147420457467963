import React, { useEffect, useState } from "react";
import { LIMITS, routesList } from "../../../../config";
import { Link, useParams } from "react-router-dom";
import Manage from "./modal";
import {
  Upload,
  Button,
  message,
  Select,
  DatePicker,
  Tooltip,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { validateFields } from "../../../../components/validation";
import moment from "moment";
import Itinerary from "./itinerary";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique filenames
import { Dropzone } from "../../../../components";
import dayjs from "dayjs";

const { Option } = Select;

export default function Handle() {
  const { id } = useParams();
  const [mangeModal, setManageModal] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [sending, setSending] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [formData, setFormData] = useState({
    duplicateEdition: "",
    project: null,
    editionName: "",
    startDate: null,
    endDate: null,
    travelStyle: null,
    maxSpots: "",
    publicSpots: "",
    hotel: null,
    destination: null,
    arrivalAirport: null,
    departureAirport: null,
    locationIdentifier: "",
    addOns: null,
    whatsappLink: "",
    heroImage: null,
    bannerImage: null,
    ipName: "",
    editionNumber: "",
    editionCost: "",
    editionDescription: "",
    visaRequirement: null,
    preSalesGuidebook: "",
    singleRoomAddonCost: "",
    region: null,
    advancePayment: "",
    starEdition: false,
    highlightImage: null,
    description: "",
    edition_status: null,
    users: [],
  });

  const [hotelList, setHotelList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const [addOnsList, setAddOnsList] = useState([]);
  const [travelStyleList, setTravelList] = useState([]);
  const [editionStatusList, setEditionStatusList] = useState([]);

  const [regions, setRegions] = useState([]);
  const [editionsDetailData, setEditionsDetailData] = useState([]);
  const [modalItinerarydata, setModalItineraryData] = useState({});
  const [itinerarydata, setItineraryData] = useState([]);
  const [editionId, setEditionId] = useState(null);
  const [files, setFiles] = useState([]);

  const [heroImagePreview, setHeroImagePreview] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [highlightImagePreview, setHighlightImagePreview] = useState(null);

  const [uploadedUrls, setUploadedUrls] = useState({
    heroImageUrl: "",
    bannerImageUrl: "",
    highlightImageUrl: "",
  });

  // State for Dropzone 1
  const [storyMediaImages, setStoryMediaImages] = useState([]);
  const [removeStoryMediaImages, setRemoveStoryMediaImages] = useState([]);
  const [storyMediaUrls, setStoryMediaUrls] = useState([]);

  // State for Dropzone 2
  const [cardMediaImages, setCardMediaImages] = useState([]);
  const [removeCardMediaImages, setRemoveCardMediaImages] = useState([]);
  const [cardMediaUrls, setCardMediaUrls] = useState([]);

  // State for Dropzone 2
  const [highLightMediaImages, setHighlightMediaImages] = useState([]);
  const [removeHighlightMediaImages, setRemoveHighlightMediaImages] = useState(
    []
  );
  const [highlightMediaUrls, setHighlightMediaUrls] = useState([]);

  const [filterData, setFilterData] = useState({
    edition: null,
    search: null,
  });

  const [editionsList, setEditionsList] = useState([]);
  const [airportLoading, setAirportLoading] = useState(false);

  const [airportList, setAirportList] = useState([]);

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [userList, setUserList] = useState([]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(
    filterData?.search
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(filterData?.search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterData?.search]);

  useEffect(() => {
    if (id) {
      getEditionsData();
      getIitineraryData();
    }
  }, [id]);

  // useEffect(() => {
  //   if (id || filterData?.edition) {
  //     getEditionsData();
  //     getIitineraryData();
  //   }
  // }, [id, filterData?.edition]);

  useEffect(() => {
    getEditionsList();
    getHotelList();
    getTravelStyleList();
    getProjectList();
    getDestinationList();
    getAddOnsList();
    getRegions();
    getUsers();
  }, []);

  useEffect(() => {
    getAirportList();

    console.log("useEffect", debouncedSearchTerm);
  }, [activePage, debouncedSearchTerm]);

  const clearInputs = () => {
    setFormData({
      duplicateEdition: "",
      project: null,
      editionName: "",
      startDate: "",
      endDate: "",
      travelStyle: null,
      maxSpots: "",
      publicSpots: "",
      hotel: null,
      destination: null,
      arrivalAirport: "",
      departureAirport: "",
      locationIdentifier: "",
      addOns: null,
      whatsappLink: "",
      heroImage: null,
      bannerImage: null,
      ipName: "",
      editionNumber: "",
      editionCost: "",
      editionDescription: "",
      visaRequirement: null,
      preSalesGuidebook: "",
      singleRoomAddonCost: "",
      region: null,
      advancePayment: "",
      starEdition: false,
      highlightImage: null,
      description: "",
      edition_status: null,
      users: [],
    });

    setHeroImagePreview(null);
    setBannerImagePreview(null);
    setHighlightMediaImages([]);
    setStoryMediaImages([]);
    setCardMediaImages([]);
  };

  const getEditionsData = (editionId = null) => {
    const selectedEdition = editionId || filterData?.edition; // Prefer passed editionId if provided
    const currentId = id || selectedEdition; // Use id from URL if available

    setEditionId(currentId);

    fetchData(`api/editions/${currentId}`).then((res) => {
      if (res.success) {
        const data = res;

        // Fetch the airport lists for arrival and departure airports using their respective search terms
        const arrivalAirportPromise = new Promise((resolve) => {
          getAirportList(data.arrival_airport_name);
          resolve();
        });

        const departureAirportPromise = new Promise((resolve) => {
          getAirportList(data.departure_airport_name);
          resolve();
        });

        // Use Promise.all to wait for both airport list fetches
        Promise.all([arrivalAirportPromise, departureAirportPromise])
          .then(() => {
            // Now you can safely set the form data after both airport labels are fetched
            setFormData({
              project: data.project || null,
              editionName: data.name || "",
              startDate: data.start_date || "",
              endDate: data.end_date || "",
              travelStyle: data.travel_style || null,
              maxSpots: data.max_spots || "",
              publicSpots: data.public_spots || "",
              hotel: data.hotel_details?.map((hotel) => hotel.id) || null, // Assuming hotel details are an array
              destination: data.destination || null,
              arrivalAirport: data.arrival_airport || "", // Label will be set by getAirportList
              departureAirport: data.departure_airport || "", // Label will be set by getAirportList
              locationIdentifier: data.location_name || "",
              addOns: data.add_on_details?.map((addon) => addon.id) || null, // Assuming add-ons are an array
              whatsappLink: data.whatsapp_link || "",
              heroImage: data.hero_image || null,
              bannerImage: data.banner_image || null,
              ipName: data.ip || "",
              editionNumber: data.edition_number || "",
              editionCost: data.cost || "",
              editionDescription: data.description || "",
              visaRequirement: data.is_visa !== null ? data.is_visa : null,
              preSalesGuidebook: data.guidebook_link || "",
              singleRoomAddonCost: data.single_room_cost || "",
              region: data.region || null,
              advancePayment: data.advance_percentage || "",
              starEdition: data.is_star_edition || false,
              description: data.highlights_text || "",
              highlightImage: data.highlight_image || null,
              edition_status: data.status,
              users: data.hosts?.map((e) => e?.uuid) || [],
            });

            setHeroImagePreview(data?.hero_image);
            setBannerImagePreview(data?.banner_image);
            setHighlightMediaImages(data?.highlights_images || []);

            setUploadedUrls({
              heroImageUrl: data?.hero_image,
              bannerImageUrl: data?.banner_image,
            });
          })
          .catch((err) => {
            // Handle error in fetching airport lists
            console.error("Error fetching airport data:", err);
            toast.error("Failed to load airport data");
          });
      } else {
        clearInputs();
        toast.error(res.message);
      }
    });
  };

  const getIitineraryData = () => {
    setLoading(true);

    fetchData(`api/itinerary/?edition=${id || editionId}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setItineraryData(res.data);
      } else {
        setItineraryData([]);
        toast.error(res.message);
      }
    });
  };

  const getUsers = () => {
    fetchData(`api/accounts/?user_type=${7}&type=${"search_user"}`).then(
      (res) => {
        if (res.success) {
          console.log(res, "res");
          const data = res.data.map((e) => {
            return {
              label: e.first_name + e.last_name,
              value: e.uuid,
            };
          });
          setUserList(data);
        } else {
          setUserList([]);
          toast.error(res.message);
        }
      }
    );
  };
  const getHotelList = () => {
    fetchData(`api/hotel/hotel-names`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setHotelList(data);
      } else {
        setHotelList([]);
        toast.error(res.message);
      }
    });
  };

  const getProjectList = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectList(data);
      } else {
        setProjectList([]);
        toast.error(res.message);
      }
    });
  };

  const getDestinationList = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/destination/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setDestinationList(data);
      } else {
        setDestinationList([]);
        toast.error(res.message);
      }
    });
  };

  const getAddOnsList = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/add-ons/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.title_of_day,
            value: e?.id,
          };
        });
        setAddOnsList(data);
      } else {
        setAddOnsList([]);
        toast.error(res.message);
      }
    });
  };

  const getEditionsList = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getTravelStyleList = () => {
    fetchData(`api/editions/editions-choices/`).then((res) => {
      if (res.success) {
        const travel_data = res["travel_style_choices"];
        const edition_status = res["edition_status_choices"];
        const data = travel_data?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });

        const edition_status_data = edition_status?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });
        setEditionStatusList(edition_status_data);
        setTravelList(data);
      } else {
        setEditionStatusList([]);
        setTravelList([]);
        toast.error(res.message);
      }
    });
  };

  const getRegions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/region/${param}`).then((res) => {
      if (res.success) {
        const newData = res.data?.map((r) => {
          return {
            label: r?.name,
            value: r?.id,
          };
        });

        setRegions(newData);
      } else {
        setRegions([]);
        toast.error(res.message);
      }
    });
  };

  const getAirportList = (searchTerm = "") => {
    const newSearch = debouncedSearchTerm || searchTerm;

    const limitNew = newSearch ? 0 : limit;
    const offsetNew = newSearch ? 0 : (activePage - 1) * limit;

    console.log(newSearch, "newSearch");
    setAirportLoading(true);
    fetchData(
      `api/project_settings/airport-names/?limit=${limitNew}&offset=${offsetNew}&search=${encodeURIComponent(
        newSearch || ""
      )}`
    ).then((res) => {
      setAirportLoading(false);
      if (res.success) {
        const newData = res.data?.map((e) => {
          return {
            label: e?.airport_name,
            value: e?.id,
          };
        });

        if (debouncedSearchTerm) {
          setAirportList((prevData) => {
            // If activePage is 1, replace the data, otherwise append to the existing list
            return activePage === 1
              ? newData
              : [...(prevData || []), ...newData];
          });
        } else {
          // If no search term, combine newData with previous records
          setAirportList((prevData) => {
            // Combine previous records with new records
            const combinedData = [...(newData || []), ...(prevData || [])];

            // Remove duplicates based on value (ID)
            const uniqueData = Array.from(
              new Set(combinedData.map((item) => item.value))
            ).map((id) => combinedData.find((item) => item.value === id));

            return uniqueData;
          });
        }

        // setAirportList((prevData) => {
        //   // If activePage is 1, replace the data, otherwise append to the existing list
        //   return activePage === 1 ? newData : [...(prevData || []), ...newData];
        // });
      } else {
        setAirportList([]); // Clear the list in case of failure
        toast.error(res.message);
      }
    });
  };

  // Set the state variables to empty arrays if the API call fails
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const fetchPresignedUrls = async (filesData) => {
    const body = {
      // upload_for: 1,
      files: filesData,
      thumbnail_files: filesData,
    };

    try {
      const response = await fetchData(
        "api/editions/pre-sign-urls/",
        "POST",
        body
      );
      if (response.success) {
        return {
          files: response.pre_signed_urls,
          thumbnails: response.pre_thumbnail_urls,
        };
      } else {
        message.error(response.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching presigned URLs:", error);
      message.error("Error fetching presigned URLs");
      return null;
    }
  };

  const uploadFiles = async (filesToUpload) => {
    if (filesToUpload.length === 0) return [];

    // Filter out files with invalid types
    const allowedTypes = ["image/jpeg", "image/png", "video/mp4"];
    const filteredFiles = filesToUpload.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (filteredFiles.length === 0) {
      console.error(
        "No valid file types selected. Only JPG, PNG, and MP4 are allowed."
      );
      return [];
    }

    // Prepare file data for presigned URL request
    const filesData = filteredFiles.map((file) => {
      const uniqueFileName = `${uuidv4()}_${file.name}`;
      return {
        file_name: uniqueFileName,
        file_type: file.type,
      };
    });

    // Fetch presigned URLs (assuming fetchPresignedUrls now also returns thumbUrl)
    const presignedUrls = await fetchPresignedUrls(filesData);
    if (!presignedUrls || presignedUrls.length === 0) {
      console.error("No presigned URLs received.");
      return [];
    }

    const { files, thumbnails } = presignedUrls;

    console.log(files, thumbnails, "files and thumbnails");

    // Start timing
    const startTime = performance.now();

    // Array to store the URLs of successfully uploaded files
    const uploadedFileUrls = [];

    // Upload each file to its corresponding presigned URL
    await Promise.all(
      filteredFiles.map(async (file, index) => {
        const presignedUrl = files[index]?.pre_signed_url;
        const fileUrl = files[index]?.file_url;
        const thumbUrl = thumbnails[index]?.file_url;
        const uniqueFileName = files[index].file_name;

        console.log();

        if (!presignedUrl) {
          console.error(`No presigned URL available for file: ${file.name}`);
          return;
        }

        try {
          // Upload original file
          const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadResponse.ok) {
            console.log(`File ${uniqueFileName} uploaded successfully!`);
            uploadedFileUrls.push(fileUrl); // Add the URL to the array
          } else {
            console.error(
              `File ${uniqueFileName} upload failed: ${uploadResponse.statusText}`
            );
          }

          // Compress image if it's an image type and upload to thumbUrl
          if (thumbUrl && file.type.startsWith("image/")) {
            const compressedImage = await compressImage(file, 200); // Compress to max 200px width/height

            const thumbResponse = await fetch(thumbUrl, {
              method: "PUT",
              headers: {
                "Content-Type": compressedImage.type,
              },
              body: compressedImage,
            });

            if (thumbResponse.ok) {
              console.log(
                `Thumbnail for ${uniqueFileName} uploaded successfully!`
              );
            } else {
              console.error(
                `Thumbnail for ${uniqueFileName} upload failed: ${thumbResponse.statusText}`
              );
            }
          }
        } catch (error) {
          console.error(
            `An error occurred while uploading file ${file.name}:`,
            error
          );
        }
      })
    );

    // End timing
    const endTime = performance.now();
    const timeTakenMs = endTime - startTime;
    const timeTakenSec = (timeTakenMs / 1000).toFixed(2);
    const timeTakenMin = Math.floor(timeTakenMs / 60000);
    const remainingSec = ((timeTakenMs % 60000) / 1000).toFixed(2);

    console.log(
      `Total time taken: ${timeTakenMin} minute(s) and ${remainingSec} second(s)`
    );

    // Return the array of successfully uploaded file URLs
    return uploadedFileUrls;
  };

  // Function to compress the image using Canvas API
  const compressImage = (file, maxSize) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          // Create canvas element
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Calculate new dimensions while maintaining the aspect ratio
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height = Math.floor((height * maxSize) / width);
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width = Math.floor((width * maxSize) / height);
              height = maxSize;
            }
          }

          // Set canvas dimensions and draw the image onto it
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (compressed image)
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject(new Error("Compression failed"));
              }
            },
            file.type,
            0.7 // Compression quality (0.7 = 70%)
          );
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      setFormData((prevState) => ({
        ...prevState,
        [name]: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "heroImage") {
          setHeroImagePreview(reader.result);
        } else if (name === "bannerImage") {
          setBannerImagePreview(reader.result);
        } else if (name === "highlightImage") {
          setHighlightImagePreview(reader.result);
        }
      };
      reader.readAsDataURL(file);

      // Upload file and update URLs
      const urls = await uploadFiles([file]);
      if (name === "heroImage") {
        setUploadedUrls((prevState) => ({
          ...prevState,
          heroImageUrl: urls[0] || "",
        }));
      } else if (name === "bannerImage") {
        setUploadedUrls((prevState) => ({
          ...prevState,
          bannerImageUrl: urls[0] || "",
        }));
      } else if (name === "highlightImage") {
        setUploadedUrls((prevState) => ({
          ...prevState,
          highlightImageUrl: urls[0] || "",
        }));
      }
    }
  };

  const handleRemoveImage = (imageType) => {
    setFormData((prevState) => ({
      ...prevState,
      [imageType]: null,
    }));
    if (imageType === "heroImage") {
      setHeroImagePreview(null);
    } else if (imageType === "bannerImage") {
      setBannerImagePreview(null);
    } else if (imageType === "highlightImage") {
      setHighlightImagePreview(null);
    }
  };

  const validationRules = {
    // duplicateEdition: {
    //   required: true, // This field is required
    //   type: "number", // Expecting a number
    //   min: 1, // Minimum value (optional)
    // },
    project: {
      required: true, // Must select a project
    },
    editionName: {
      required: true, // Edition name is required
      maxLength: 100, // Maximum length of 100 characters
    },
    startDate: {
      required: true, // Start date is required
    },
    endDate: {
      required: true, // End date is required
    },
    travelStyle: {
      required: true, // Travel style must be selected
    },
    maxSpots: {
      required: true,
      type: "number",
      min: 1, // Min number of spots
    },
    publicSpots: {
      required: true,
      type: "number",
      min: 0, // Min public spots (optional)
    },
    hotel: {
      required: true, // Must select a hotel
    },
    destination: {
      required: true, // Destination is required
    },
    arrivalAirport: {
      required: true, // Arrival airport is required
    },
    departureAirport: {
      required: true, // Departure airport is required
    },
    locationIdentifier: {
      required: true,
      maxLength: 100, // Optional, max length of 10 characters
    },
    addOns: {
      required: true,
    },
    whatsappLink: {
      required: true, // WhatsApp link is required
      type: "url", // Must be a valid URL
    },
    // heroImage: {
    //   required: true, // WhatsApp link is required
    //   type: "image" || "url", // Must be a valid image
    // },
    // bannerImage: {
    //   required: true, // WhatsApp link is required
    //   type: "image" || "url", // Must be a valid image
    // },
    ipName: {
      required: true, // IP name is required
      maxLength: 100,
    },
    editionNumber: {
      required: true,
      type: "number",
      min: 1,
    },
    editionCost: {
      required: true,
      type: "number",
      min: 0, // Cost should be a non-negative number
    },
    editionDescription: {
      required: true,
      maxLength: 500, // Optional, max 500 characters
    },
    visaRequirement: {
      maxLength: 200, // Optional, max 200 characters
    },
    preSalesGuidebook: {
      required: true,
      type: "url",
    },
    singleRoomAddonCost: {
      required: true,
      type: "number", // Must be a number
      min: 0, // Minimum cost is 0
    },
    region: {
      required: true,
    },
    advancePayment: {
      required: true,
      type: "number",
      min: 0,
    },
  };

  const handleSubmit = () => {
    const { isValid, errors } = validateFields(formData, validationRules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Adding Edition");
    }

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      project: formData.project,
      name: formData.editionName,
      start_date: formData.startDate,
      end_date: formData.endDate,
      travel_style: formData.travelStyle,
      max_spots: formData.maxSpots,
      public_spots: formData.publicSpots,
      destination: formData.destination, // Destination ID
      hotel: formData.hotel, // Hotel IDs in an array (assume multiple hotel selection)
      arrival_airport: formData.arrivalAirport, // Arrival airport code
      departure_airport: formData.departureAirport, // Departure airport code
      location_name: formData.locationIdentifier, // Location name (optional field)
      add_on: formData.addOns, // Add-ons IDs in an array
      // ...(uploadedUrls?.heroImageUrl && isURL(uploadedUrls?.heroImageUrl)
      //   ? {}
      //   : { hero_image: uploadedUrls?.heroImageUrl || "" }),
      // ...(uploadedUrls?.bannerImageUrl && isURL(uploadedUrls?.bannerImageUrl)
      //   ? {}
      //   : { banner_image: uploadedUrls?.bannerImageUrl || "" }),
      hero_image: uploadedUrls?.heroImageUrl || "",
      banner_image: uploadedUrls?.bannerImageUrl || "",
      whatsapp_link: formData.whatsappLink, // WhatsApp link
      ip: formData.ipName, // IP name
      edition_number: formData.editionNumber, // Edition number
      cost: formData.editionCost, // Edition cost
      description: formData.editionDescription, // Edition description
      is_visa: formData.visaRequirement, // Visa requirement boolean
      guidebook_link: formData.preSalesGuidebook, // Guidebook link (optional)
      single_room_cost: formData.singleRoomAddonCost, // Single room add-on cost
      region: formData.region, // Region ID
      advance_percentage: formData.advancePayment, // Advance payment percentage
      is_star_edition: formData?.starEdition,

      // story_media: storyMediaUrls,
      // card_media: cardMediaUrls,

      highlights_images: highlightMediaUrls,
      highlights_text: formData.description,
      ...(removeHighlightMediaImages
        ? { delete_images: removeHighlightMediaImages }
        : {}),

      related_edition: isDuplicate ? editionId : "",

      ...(!editionId || !id ? {} : { status: formData?.edition_status || "" }),
      hosts: formData.users,
    };

    console.log(body, "body");

    let api = id ? `api/editions/${id}/` : "api/editions/";
    let method = id ? "put" : "post";

    fetchData(api, method, body, "", true).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        setEditionId(res?.id);
        setIsDuplicate(false);

        if (id) {
          setActivePage(1);
          getEditionsData();
          setHighlightMediaUrls([]);
          toast.success("Success");
        } else {
          setEditionId(res?.id);
          clearInputs();

          setFilterDataValue("edition", null);
          setManageModal(true);
          toast.success("Please Add Itinerary To Complete Edition");
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleEdit = (data) => {
    setModalItineraryData(data);
    setManageModal(true);
  };

  // Function to disable dates before the start date
  const disabledDate = (current) => {
    // Can not select days before the start date
    return current && current < dayjs(formData.startDate).startOf("day");
  };

  // Function to format the ISO date string to a Day.js object
  const parseISODate = (dateString) => {
    return dateString ? dayjs(dateString) : null;
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  console.log(removeHighlightMediaImages, "removeHighlightMediaImages");

  console.log(highlightMediaUrls, "highlightMediaUrls");

  // console.log(cardMediaUrls, "cardMediaUrls");

  // console.log(storyMediaUrls, "storyMediaUrls");

  const editionsList1 = [
    { value: "edition1", label: "Edition 1" },
    { value: "edition2", label: "Edition 2" },
    { value: "edition3", label: "Edition 2" },
    { value: "edition4", label: "Edition 2" },
    { value: "edition5", label: "Edition 2" },
    { value: "edition6", label: "Edition 2" },
    { value: "edition7", label: "Edition 2" },
    { value: "edition8", label: "Edition 2" },
    { value: "edition9", label: "Edition 2" },
    { value: "edition10", label: "Edition 2" },
    { value: "edition11", label: "Edition 2" },
    { value: "edition12", label: "Edition 2" },
    { value: "edition13", label: "Edition 2" },
    { value: "edition14", label: "Edition 2" },
    { value: "edition15", label: "Edition 2" },
    { value: "edition16", label: "Edition 2" },
    { value: "edition17", label: "Edition 2" },
    // Add more editions as needed
  ];

  console.log(isDuplicate, "isDuplicate");
  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className={`${id ? "col-xxl-6" : "col-xxl-6"}`}>
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header ">
              <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center">
                <Link
                  to={routesList["manage_editions"].path}
                  className="d-flex me-3 btn btn-sm btn-icon btn-light-primary"
                >
                  <i className="ki-outline ki-arrow-left fs-2 " />
                </Link>
                {id ? "Edit Edition" : "Add Edition"}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pe-0">
              <div className="scroll-y h-600px pe-5">
                <div>
                  <div className="row g-5 mb-5 align-items-center">
                    {(!id || !editionId) && (
                      <>
                        <div className="col-md-12 fv-row">
                          {" "}
                          <h4 className="mb-3">Duplicate Edition</h4>
                        </div>

                        <div className="col-md-12 fv-row mb-5">
                          <Select
                            value={filterData?.edition}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => {
                              setFilterDataValue("edition", value);
                              setIsDuplicate(true);
                              getEditionsData(value);
                            }}
                            placeholder="Select Edition"
                            style={{ width: "100%" }}
                            // dropdownStyle={{
                            //   maxHeight: "100px",
                            //   overflow: "auto",
                            // }}
                            options={editionsList}
                            allowClear
                            onClear={() => {
                              setTimeout(() => {
                                if (isDuplicate) {
                                  setIsDuplicate(false);
                                  clearInputs();
                                }
                              }, 400);

                              setFilterDataValue("edition", null);
                              getEditionsData(null);
                            }}
                            onPopupScroll={(e) => {
                              const bottom =
                                e.target.scrollHeight ===
                                e.target.scrollTop + e.target.clientHeight;

                              if (bottom) {
                              }

                              console.log(bottom, "bottom");
                            }}
                            size="large"
                          />
                        </div>
                      </>
                    )}

                    {/* Story Media */}
                    {/* <div className="col-md-12 fv-row">
                      <h4>Story Media</h4>
                      <Dropzone
                        pictures={storyMediaImages}
                        setPictures={setStoryMediaImages}
                        removedImages={removeStoryMediaImages}
                        setRemovedImages={setRemoveStoryMediaImages}
                        shouldUpload={true} // Pass the flag to Dropzone 1
                        setUploadedUrls={setStoryMediaUrls}
                      />
                    </div> */}

                    {/* Card Media */}
                    {/* <div className="col-md-12 fv-row">
                      <h4>Card Media</h4>

                      <Dropzone
                        pictures={cardMediaImages}
                        setPictures={setCardMediaImages}
                        removedImages={removeCardMediaImages}
                        setRemovedImages={setRemoveCardMediaImages}
                        shouldUpload={true} // Pass the flag to Dropzone 2
                        setUploadedUrls={setCardMediaUrls}
                      />
                    </div> */}
                  </div>
                </div>

                {(!id || !editionId) && (
                  <>
                    <div className="separator separator my-3 separator-dashed border-gray-300" />
                  </>
                )}

                <div className="row g-5 mb-5 align-items-center">
                  <div className="col-md-12 fv-row">
                    <h4>
                      {id || editionId
                        ? "Edit Existing Edition"
                        : "Create New Edition"}
                    </h4>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Select Project</label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="project"
                      value={formData.project}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          project: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={projectList}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Edition Name</label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="editionName"
                      value={formData.editionName}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Start Date</label>
                    <DatePicker
                      showTime={{ format: "HH:mm" }} // Show time picker
                      format="YYYY-MM-DD HH:mm" // Format date as YYYY-MM-DD HH:mm
                      value={
                        formData.startDate
                          ? parseISODate(formData.startDate)
                          : null
                      }
                      onChange={(date, dateString) => {
                        setFormData({
                          ...formData,
                          startDate: dateString, // Update the appropriate date field with the formatted date
                        });
                      }} // Handle date change
                      className="fs-7 w-100"
                      placeholder="Pick a date"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">End Date</label>
                    <DatePicker
                      showTime={{ format: "HH:mm" }} // Show time picker
                      format="YYYY-MM-DD HH:mm" // Format date as YYYY-MM-DD HH:mm
                      value={
                        formData.endDate ? parseISODate(formData.endDate) : null
                      }
                      onChange={(date, dateString) => {
                        setFormData({
                          ...formData,
                          endDate: dateString, // Update the appropriate date field with the formatted date
                        });
                      }}
                      className="fs-7 w-100"
                      placeholder="Pick a date"
                      disabledDate={disabledDate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Travel Style</label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="travelStyle"
                      value={formData.travelStyle}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          travelStyle: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={travelStyleList}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Max Spots (Internal)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="maxSpots"
                      value={formData.maxSpots}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Number of Spots (Public)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="publicSpots"
                      value={formData.publicSpots}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Add Hotel</label>
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="label"
                      name="hotel"
                      value={formData.hotel}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          hotel: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={hotelList}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Destination</label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="destination"
                      value={formData.destination}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          destination: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={destinationList}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Arrival Airport</label>
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={formData?.arrivalAirport}
                      showSearch
                      onSearch={(value) => {
                        console.log(value, "value");
                        setFilterData((prevState) => ({
                          ...prevState,
                          search: value,
                        }));
                      }}
                      optionFilterProp="label"
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          arrivalAirport: value,
                        }));
                      }}
                      placeholder="Select Arrival Airport"
                      style={{ width: "100%" }}
                      options={airportList}
                      allowClear
                      onClear={() => {
                        setFormData((prevState) => ({
                          ...prevState,
                          arrivalAirport: null,
                        }));
                        setActivePage(1);
                      }}
                      onPopupScroll={(e) => {
                        const bottom =
                          Math.abs(
                            e.target.scrollHeight -
                              (e.target.scrollTop + e.target.clientHeight)
                          ) < 1.5;

                        if (bottom) {
                          setAirportLoading(true);
                          setActivePage((prevState) => prevState + 1);
                        }
                      }}
                      notFoundContent={
                        <>
                          <div className="d-flex justify-content-center align-items-center">
                            {airportLoading ? (
                              <Spin size="small" />
                            ) : (
                              "No Data Found"
                            )}
                          </div>
                        </>
                      }
                      size="large"
                      loading={airportLoading}
                    ></Select>

                    {/* <input
                      type="text"
                      className="form-control fs-7"
                      name="arrivalAirport"
                      value={formData.arrivalAirport}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    /> */}
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Departure Airport
                    </label>

                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={formData?.departureAirport}
                      showSearch
                      onSearch={(value) => {
                        console.log(value, "value");
                        setFilterData((prevState) => ({
                          ...prevState,
                          search: value,
                        }));
                      }}
                      optionFilterProp="label"
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          departureAirport: value,
                        }));
                      }}
                      placeholder="Select Arrival Airport"
                      style={{ width: "100%" }}
                      options={airportList}
                      allowClear
                      onClear={() => {
                        setFormData((prevState) => ({
                          ...prevState,
                          departureAirport: null,
                        }));
                        setActivePage(1);
                      }}
                      onPopupScroll={(e) => {
                        console.log(e, "e");
                        const bottom =
                          Math.abs(
                            e.target.scrollHeight -
                              (e.target.scrollTop + e.target.clientHeight)
                          ) < 1.5;

                        console.log(e.target.scrollHeight, "bottom");
                        console.log(
                          e.target.scrollTop + e.target.clientHeight,
                          "bottom"
                        );

                        console.log(bottom, "bottom");

                        if (bottom) {
                          setAirportLoading(true);
                          setActivePage((prevState) => prevState + 1);
                        }
                      }}
                      notFoundContent={
                        <>
                          <div className="d-flex justify-content-center align-items-center">
                            {airportLoading ? (
                              <Spin size="small" />
                            ) : (
                              "No Data Found"
                            )}
                          </div>
                        </>
                      }
                      size="large"
                      loading={airportLoading}
                    ></Select>
                    {/* <input
                      type="text"
                      className="form-control fs-7"
                      name="departureAirport"
                      value={formData.departureAirport}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    /> */}
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Name of Location/s (Identifier)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="locationIdentifier"
                      value={formData.locationIdentifier}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Add Add-Ons</label>
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="label"
                      name="addOns"
                      value={formData.addOns}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          addOns: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={addOnsList}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Whatsapp Link (Closed Group)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="whatsappLink"
                      value={formData.whatsappLink}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div
                    className="col-md-6 fv-row"
                    style={{ pointerEvents: isDuplicate ? "none" : "auto" }}
                  >
                    <label className="fs-6 fw-bold mb-2">
                      Hero Image (1600x900px)
                    </label>
                    {heroImagePreview ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <img
                          src={heroImagePreview}
                          alt="Hero Preview"
                          style={{
                            width: "150px",
                            height: "120px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleRemoveImage("heroImage")}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7"
                        name="heroImage"
                        onChange={handleFileChange}
                        disabled={isDuplicate}
                      />
                    )}
                  </div>

                  <div
                    className="col-md-6 fv-row"
                    style={{ pointerEvents: isDuplicate ? "none" : "auto" }}
                  >
                    <label className="fs-6 fw-bold mb-2">
                      Banner Image (640x300px)
                    </label>

                    {bannerImagePreview ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <img
                          src={bannerImagePreview}
                          alt="Baneer Preview"
                          style={{
                            width: "150px",
                            height: "120px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleRemoveImage("bannerImage")}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7"
                        name="bannerImage"
                        onChange={handleFileChange}
                        disabled={isDuplicate}
                      />
                    )}
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Name of IP</label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="ipName"
                      value={formData.ipName}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Edition Number</label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="editionNumber"
                      value={formData.editionNumber}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Cost of Edition</label>
                    <input
                      type="number"
                      className="form-control fs-7"
                      name="editionCost"
                      value={formData.editionCost}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Edition Description
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="editionDescription"
                      value={formData.editionDescription}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Visa Requirement
                    </label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="visaRequirement"
                      value={formData.visaRequirement}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          visaRequirement: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={["Yes", "No"].map((e) => {
                        return {
                          label: e,
                          value: e === "Yes" ? true : false,
                        };
                      })}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Pre Sales - Guidebook Link
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="preSalesGuidebook"
                      value={formData.preSalesGuidebook}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Cost of Single Room Addon
                    </label>
                    <input
                      type="number"
                      className="form-control fs-7"
                      name="singleRoomAddonCost"
                      value={formData.singleRoomAddonCost}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Region (important for tax calc + visa)
                    </label>

                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="region"
                      value={formData.region}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          region: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={regions}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-9 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      How much % advance do we need as payment 1?
                    </label>
                    <input
                      type="number"
                      className="form-control fs-7"
                      name="advancePayment"
                      value={formData.advancePayment}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-3 fv-row">
                    <label className="fs-6 fw-bold mb-2">Star Edition</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="starEdition"
                        name="starEdition"
                        checked={formData.starEdition === "true"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setFormData({
                            ...formData,
                            starEdition: isChecked ? "true" : "false",
                          });
                        }}
                        className="form-check-input"
                        disabled={isDuplicate}
                      />
                      <label
                        htmlFor="starEdition"
                        className="form-check-label fs-7"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                  {(id || editionId) && (
                    <div className="col-md-6 fv-row mb-5">
                      <label className="fs-6 fw-bold mb-2">
                        Edition Status
                      </label>
                      <Select
                        showSearch
                        optionFilterProp="label"
                        name="edition_status"
                        value={formData.edition_status}
                        onChange={(value) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            edition_status: value,
                          }));
                        }}
                        placeholder="Choose Edition Status"
                        className="fs-7 w-100"
                        style={{ width: "100%" }}
                        dropdownStyle={{ zIndex: 1100 }}
                        size="large"
                        options={editionStatusList}
                        disabled={isDuplicate}
                      ></Select>
                    </div>
                  )}

                  {(id || editionId) && (
                    <div className="col-md-6 fv-row mb-5">
                      <label className="fs-6 fw-bold mb-2">
                        Select Lead Facilitator
                      </label>
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="label"
                        name="users"
                        value={formData.users}
                        onChange={(value) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            users: value,
                          }));
                        }}
                        placeholder="Select Users"
                        className="fs-7 w-100"
                        style={{ width: "100%" }}
                        dropdownStyle={{ zIndex: 1100 }}
                        size="large"
                        options={userList}
                        disabled={isDuplicate}
                      ></Select>
                    </div>
                  )}

                  <div className="separator separator mt-7 separator-dashed border-gray-300" />

                  <div className="col-md-12 fv-row">
                    <label className="fs-3 fw-bold mb-2">Highlights</label>
                  </div>

                  <div
                    className="col-md-12 fv-row"
                    style={{ pointerEvents: isDuplicate ? "none" : "auto" }}
                  >
                    <label className="fs-6 fw-bold mb-2">Images</label>

                    <Dropzone
                      pictures={highLightMediaImages}
                      setPictures={setHighlightMediaImages}
                      removedImages={removeHighlightMediaImages}
                      setRemovedImages={setRemoveHighlightMediaImages}
                      shouldUpload={true}
                      setUploadedUrls={setHighlightMediaUrls}
                      setLoading={setUploadLoading}
                    />

                    {/* {highlightImagePreview ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <img
                          src={highlightImagePreview}
                          alt="Hero Preview"
                          style={{
                            width: "150px",
                            height: "120px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleRemoveImage("highlightImage")}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7"
                        name="highlightImage"
                        onChange={handleFileChange}
                      />
                    )} */}
                  </div>

                  <div className="col-md-12 fv-row">
                    <label className="fs-6 fw-bold mb-2">Description</label>
                    <textarea
                      className="form-control fs-7"
                      name="description"
                      rows={4}
                      value={formData.description}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    ></textarea>
                  </div>

                  <div className="col-md-4 fv-row">
                    <button
                      disabled={uploadLoading}
                      className="btn btn-primary fs-7 fw-bold"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <i className="ki-outline ki-plus-square fs-2" />
                      {isDuplicate
                        ? "Duplicate Edition"
                        : id
                        ? "Update Edition"
                        : "Create Edition"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>

        <div
          className="col-xxl-6"
          style={
            loading
              ? {
                  filter: "blur(5px)",
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header ">
              <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center ">
                Itinerary
              </h3>
              <div className="card-toolbar">
                <Tooltip
                  title={`${
                    editionId || id ? "" : "First Add Editions To Add Itinerary"
                  }`}
                >
                  <span>
                    {" "}
                    {/* Wrap the button with a span to allow tooltip on disabled state */}
                    <button
                      className="btn btn-primary fs-7 fw-bold"
                      onClick={() => {
                        setManageModal(true);
                      }}
                      disabled={!editionId && !id}
                    >
                      <i className="ki-outline ki-plus-square fs-2" /> Add
                      Itinerary
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}

            <Itinerary cardData={itinerarydata} onEdit={handleEdit} />
          </div>
        </div>
      </div>

      <Manage
        show={mangeModal}
        destinationList={destinationList}
        hotelList={hotelList}
        onHide={() => {
          setModalItineraryData({});
          setManageModal(false);
          setLoading(true);
          getIitineraryData();
        }}
        data={{
          edition_id: id || editionId,
          itinerary_id: modalItinerarydata?.id,
        }}
      />
    </div>
  );
}
