import React, { useEffect, useState } from "react";
import { ManageAddOnsModal } from "./modals";
import { LIMITS } from "../../../config";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Alert, Loader, Pagination } from "../../../components";
import ImagePreview from "../../../components/validation";

import { Button, Image, Select } from "antd";

export default function AddOns() {
  const [manageAddOns, setManageAddOns] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [destinationList, setDestinationList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [hotelNameList, setHotelNameList] = useState([]);
  const [hotelName, setHotelName] = useState(null);
  const [destinationName, setDestinationName] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  // Create a state for all the parameters in a single object
  const [filterData, setFilterData] = useState({
    activePage: 1,
    limit: 10,
    debouncedSearchTerm: null,
    hotelName: null,
  });

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // useEffect(() => {
  //   getDestination();
  //   getExperience();
  //   getHotel();
  //   getHotelNames();
  // }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, filterData, debouncedSearchTerm]);

  const getDestination = () => {
    fetchData("api/destination/").then((res) => {
      if (res.success) {
        setDestinationList(res.data);
      } else {
        setDestinationList([]);
        toast.error(res.message);
      }
    });
  };

  const getExperience = () => {
    fetchData("api/experience/").then((res) => {
      if (res.success) {
        setExperienceList(res.data);
      } else {
        setExperienceList([]);
        toast.error(res.message);
      }
    });
  };

  const getHotel = () => {
    fetchData("api/hotel/").then((res) => {
      if (res.success) {
        setHotelList(res.data);
      } else {
        setHotelList([]);
        toast.error(res.message);
      }
    });
  };

  const getHotelNames = () => {
    fetchData("api/hotel/hotel-names/").then((res) => {
      if (res.success) {
        setHotelNameList(res.data);
      } else {
        setHotelNameList([]);
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    const { activePage, limit, hotelName, destinationName } = filterData;

    setLoading(true);

    const url = `api/add-ons/?limit=${limit || 10}&offset=${
      (activePage - 1) * limit || 0
    }${
      debouncedSearchTerm
        ? `&search=${encodeURIComponent(debouncedSearchTerm)}`
        : ""
    }${hotelName ? `&hotel=${hotelName}` : ""}${
      destinationName ? `&destination=${destinationName}` : ""
    }`;

    fetchData(url).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(`api/add-ons/${selected.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search add ons here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div>
                  {/* <div className="d-flex align-items-center position-relative">
                    <div className="ms-3">
                      <Select
                        value={filterData?.hotelName}
                        showSearch
                        optionFilterProp="label"
                        onChange={(value) => {
                          setFilterData((prevParams) => ({
                            ...prevParams,
                            hotelName: value,
                          }));

                          // setHotelName(value);
                        }}
                        style={{ width: "200px" }}
                        placeholder="Select a hotel"
                        options={hotelNameList.map((h) => ({
                          label: h.name,
                          value: h.id,
                        }))}
                        allowClear
                        onClear={() => {
                          setFilterData((prevParams) => ({
                            ...prevParams,
                            hotelName: null,
                          }));
                          // setHotelName(null);
                        }}
                        size="large"
                      />
                    </div>

                    <div className="ms-3">
                      <Select
                        value={filterData?.destinationName}
                        showSearch
                        optionFilterProp="label"
                        onChange={(value) => {
                          setFilterData((prevParams) => ({
                            ...prevParams,
                            destinationName: value,
                          }));
                          // setDestinationName(value);
                        }}
                        style={{ width: "200px" }}
                        placeholder="Select a Destination"
                        options={destinationList.map((h) => ({
                          label: h.name,
                          value: h.id,
                        }))}
                        allowClear
                        onClear={() => {
                          setFilterData((prevParams) => ({
                            ...prevParams,
                            destinationName: null,
                          }));
                          // setDestinationName(null);
                        }}
                        size="large"
                      />
                    </div>
                  </div> */}

                  {/* <button class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_itinerary"><i class="ki-outline ki-plus-square fs-2"></i> Add Itinerary </button> */}
                </div>

                <div className="position-relative mr-4"></div>
              </div>
              <div className="card-toolbar">
                <button
                  className="btn btn-primary fs-7 fw-bold"
                  onClick={() => {
                    setManageAddOns(true);
                  }}
                >
                  <i className="ki-outline ki-plus-square fs-2" /> Add Add-ons
                </button>
                {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add New User</a> */}
              </div>
            </div>
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        <table
                          className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                          id
                        >
                          <thead>
                            <tr className="fw-bolder text-gray-900 bg-light-primary">
                              <th class="min-w-75px">Sr. No.</th>
                              {/* <th className="min-w-150px">Day Number</th> */}
                              <th className="min-w-175px">Title of the day</th>
                              <th className="min-w-175px">
                                Add-ons Description
                              </th>
                              <th className="min-w-150px">Add-ons Image</th>
                              <th className="min-w-150px">Cost</th>

                              {/* <th className="min-w-100px">Hotel</th>
                              <th className="min-w-125px">Destinations</th>
                              <th className="min-w-125px">Experience</th>
                              <th className="min-w-125px">About this day</th> */}
                              <th className="min-w-110px">Action</th>
                            </tr>
                          </thead>
                          <tbody className=" fw-semibold fs-7">
                            {data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1 || "-"}</td>

                                    {/* <td>{item.day_number || "-"}</td> */}
                                    <td>{item.title_of_day || "-"}</td>
                                    <td>{item.description || "-"}</td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="symbol symbol-60px overflow-hidden me-3">
                                          <Image
                                            src={
                                              item?.default_image ??
                                              "/media/img.jpg"
                                            }
                                            alt={`${item.title_of_day} Banner`}
                                            width={90}
                                            height={90}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>{item.cost || "-"}</td>

                                    {/* <td>{item.hotel_name || "-"}</td>
                                    <td>{item.destination_name || "-"}</td>
                                    <td>{item.experience_about || "-"}</td>
                                    <td>{item.one_line_about || "-"}</td> */}

                                    <td>
                                      <div className="d-flex flex-shrink-0 ">
                                        <span
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm me-3"
                                          onClick={() => {
                                            setManageAddOns(true);
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-pencil fs-2 text-dark" />
                                        </span>
                                        <span
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-trash fs-2 text-danger" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <span>No records</span>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>
      </div>
      <ManageAddOnsModal
        item={selected}
        destinationList={destinationList}
        experienceList={experienceList}
        hotelList={hotelList}
        show={manageAddOns}
        onHide={() => {
          setSelected(null);
          setManageAddOns(false);
        }}
        onSuccess={() => {
          getData();
        }}
      />
      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onSuccess={() => {
          getData();
        }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
