import React, { useState } from "react";
import { MaxSpotsModal, SettingsModal, RedEditionModal } from "./modals";
import { Link } from "react-router-dom";
import { routesList } from "../../../config";
import circularProgressBar from "../../../components/CirculerProgressBar";

export default function Table({ data }) {
  const [settingsModal, setSettingsModal] = useState(false);
  const [maxSpotsModal, setMaxSpotsModal] = useState(false);
  const [redEditionModal, setRedEditionModal] = useState(false);

  const handleSettingsModal = () => {
    // logic for opening settings modal
  };

  const handleMaxSpotsModal = () => {
    // logic for opening max spots modal
  };

  const handleRedEditionModal = () => {
    // logic for opening red edition modal
  };

  return (
    <div>
      <div className="table-responsive tbl-sales">
        {/*begin::Table*/}
        <div className="table-responsive tbl-sales">
          <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d">
            <thead>
              <tr className="fw-bolder text-gray-900 bg-light-primary">
                <th className="min-w-250px">Edition</th>
                <th className="min-w-90px">Vibe score</th>
                <th className="min-w-50px">Sold</th>
                <th className="min-w-90px">Conv. %</th>
                <th className="min-w-80px">Payments</th>
                <th className="min-w-100px">Preferences</th>
                <th className="min-w-85px">Avg. Age</th>
                <th className="min-w-175px">Price</th>
                <th className="min-w-150px">Control</th>
              </tr>
            </thead>
            <tbody className="fw-semibold fs-7">
              {data.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setRedEditionModal(!redEditionModal);
                      }}
                    >
                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3 cursor-pointer">
                        {item?.hero_image ? (
                          <img src={item?.hero_image} alt="" width={60} />
                        ) : (
                          <div class="symbol symbol-circle symbol-50px mr-3">
                            <span
                              class="symbol-label fs-2 text-primary bg-light-primary"
                              style={{
                                border: "1px dashed #7843e6",
                                borderRadius: "50%",
                              }}
                            >
                              {item?.name?.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-column">
                        <a
                          href="#"
                          className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6"
                        >
                          {item.name || "-"}
                        </a>
                        <div className="text-gray-500">
                          {new Date(item.start_date).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span> {circularProgressBar(item.vibe_score, 35, 3)}</span>
                  </td>
                  <td>{item.sold_count || "-"}</td>
                  <td>{item.conv || "-"}</td>
                  <td>{item.payment_count || "-"}</td>
                  <td>{item.preference_count || "-"}</td>
                  <td>37</td>
                  <td>
                    <div className="fw-bolder fs-6 text-gray-900">
                      ₹{parseFloat(item.cost || "-").toLocaleString()}{" "}
                      <small className="text-gray-600">
                        / Advance {item.advance_percentage || "-"}
                      </small>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center mb-2">
                      <button
                        className="btn bg-gray-200 btn-sm me-1 d-flex"
                        style={{ padding: "4px 6px" }}
                      >
                        M <span className="badge badge-primary ms-2">7</span>
                      </button>
                      <button
                        className="btn bg-gray-200 btn-sm me-1 d-flex"
                        style={{ padding: "4px 6px" }}
                      >
                        F <span className="badge badge-primary ms-2">7</span>
                      </button>
                      <button
                        className="btn bg-gray-200 btn-sm me-3 d-flex"
                        style={{ padding: "4px 6px" }}
                      >
                        O <span className="badge badge-primary ms-2">7</span>
                      </button>
                      <div>Ratio: 0:0</div>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setSettingsModal(!settingsModal);
                        }}
                      >
                        <i className="ki-outline ki-gear text-gray-800 ms-3 fs-2" />
                      </span>
                      <Link to={`/manage/editions/handle/${item.id}`}>
                        <i className="ki-outline ki-pencil text-primary ms-3 fs-3" />
                      </Link>
                    </div>
                    <div>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setMaxSpotsModal(!maxSpotsModal);
                        }}
                      >
                        <span className="badge badge-outline badge-primary mt-0">
                          Max Spots:{" "}
                          <span className="fw-bolder text-gray-800 ms-1">
                            {item?.max_spots || "-"}
                          </span>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/*end::Table*/}
      </div>

      <SettingsModal
        show={settingsModal}
        onHide={() => {
          setSettingsModal(false);
        }}
      />
      <MaxSpotsModal
        show={maxSpotsModal}
        onHide={() => {
          setMaxSpotsModal(false);
        }}
      />
      <RedEditionModal
        show={redEditionModal}
        onHide={() => {
          setRedEditionModal(false);
        }}
      />
    </div>
  );
}
