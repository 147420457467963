import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";

export default function Project({ show, item, onHide }) {
  const [sending, setSending] = useState(false);
  const [projectData, setProjectData] = useState({
    projectName: "",
    description: "",
  });
  const [images, setImages] = useState({
    heroImage: null,
    bannerImage: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    heroImagePreview: null,
    bannerImagePreview: null,
  });

  useEffect(() => {
    if (item) {
      setProjectData({
        projectName: item?.name,
        description: item?.description,
      });

      setImages({
        heroImage: item?.hero_image,
        bannerImage: item?.banner_image,
      });

      setImagePreviews({
        heroImagePreview: item?.hero_image,
        bannerImagePreview: item?.banner_image,
      });
    }
  }, [item]);

  const onSubmit = () => {
    const { heroImage, bannerImage } = images || {};
    const { projectName, description } = projectData || {};

    if (!heroImage || !bannerImage || !projectName || !description) {
      toast.error(
        !heroImage
          ? "Hero Image is required!"
          : !bannerImage
          ? "Banner Image is required!"
          : !projectName
          ? "Project Name is required!"
          : "Description is required!"
      );
      return;
    }

    if (projectName.length > 64) {
      toast.error("Project Name cannot exceed 64 characters!");
      return;
    }

    if (description.length > 500) {
      toast.error("Description cannot exceed 500 characters!");
      return;
    }

    setSending(true);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      ...(projectData?.projectName !== item?.name && {
        name: projectData?.projectName,
      }),
      ...(projectData?.description !== item?.description && {
        description: projectData?.description,
      }),
      ...(images?.heroImage && isURL(images?.heroImage)
        ? {}
        : { hero_image: images?.heroImage || "" }),
      ...(images?.bannerImage && isURL(images?.bannerImage)
        ? {}
        : { banner_image: images?.bannerImage || "" }),
    };

    let api = item?.id ? `api/project/${item?.id}/` : "api/project/";
    let method = item?.id ? "put" : "post";

    fetchData(api, method, body, "", true).then((res) => {
      setSending(false);
      if (res.success) {
        toast.success("Success");
        onHide();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleImagesChange = (e, imageType) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;

          // Define dimensions for the banner image only
          const requiredDimensions = {
            bannerImage: { width: 640, height: 300 },
          };

          // Check dimensions based on imageType
          const requiredWidth = requiredDimensions[imageType]?.width;
          const requiredHeight = requiredDimensions[imageType]?.height;

          if (
            imageType === "bannerImage" &&
            (width !== requiredWidth || height !== requiredHeight)
          ) {
            const message = "Banner image dimensions must be 640x300px";
            toast.error(message);
            handleRemoveImages(imageType);
          } else {
            // If dimensions are correct or imageType is not "bannerImage", update the state
            setImages((prevImages) => ({
              ...prevImages,
              [imageType]: file,
            }));

            setImagePreviews((prevImagePreviews) => ({
              ...prevImagePreviews,
              [`${imageType}Preview`]: reader.result,
            }));
          }
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImages = (imageType) => {
    setImages((prevImages) => ({
      ...prevImages,
      [imageType]: null,
    }));

    setImagePreviews((prevImagePreviews) => ({
      ...prevImagePreviews,
      [`${imageType}Preview`]: null,
    }));
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {item?.id ? "Edit Project" : "Add New Project"}
        </h3>
      </Modal.Header>
      <div className="modal-body pb-7 pt-5 px-lg-10">
        <div
          className="scroll-y pe-7 ps-3"
          id="kt_modal_add_customer_scroll"
          style={{
            height: "400px",
          }}
        >
          <div className="row g-5 mb-5 align-items-center">
            <div className="col-md-6 fv-row mt-10">
              <label htmlFor className="fs-6 fw-bold mb-2 required">
                Hero Image <small className="text-muted">(For Icon)</small>
              </label>

              {imagePreviews?.heroImagePreview ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={imagePreviews?.heroImagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={() => handleRemoveImages("heroImage")}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => handleImagesChange(e, "heroImage")}
                />
              )}
            </div>

            <div className="col-md-6 fv-row  mt-10">
              <label htmlFor className="fs-6 fw-bold mb-2 required">
                Banner Image
              </label>
              {imagePreviews?.bannerImagePreview ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={imagePreviews?.bannerImagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2 "
                    onClick={() => handleRemoveImages("bannerImage")}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => handleImagesChange(e, "bannerImage")}
                />
              )}
            </div>

            <div className="col-md-12 fv-row mt-10">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Name of Project
              </label>
              <input
                type="text"
                className="form-control fs-7"
                value={projectData?.projectName || ""}
                onChange={(e) => {
                  const common_data = {
                    ...projectData,
                    ["projectName"]: e.target.value,
                  };
                  setProjectData(common_data);
                }}
              />
            </div>

            <div className="col-md-12 fv-row ">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Description
              </label>
              <textarea
                name
                className="form-control fs-7"
                id
                cols={30}
                rows={3}
                value={projectData?.description || ""}
                onChange={(e) => {
                  const common_data = {
                    ...projectData,
                    ["description"]: e.target.value,
                  };
                  setProjectData(common_data);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmit}
          disabled={sending}
        >
          {sending ? (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
