import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { Dropzone } from "../../../../components";
import { Select, Tooltip } from "antd";
import { validateFields } from "../../../../components/validation";

export default function Manage({
  show,
  destinationList,
  hotelList,
  onHide,
  data,
}) {
  const [image, setImage] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [modalItinerarydata, setModalItineraryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const [formState, setFormState] = useState({
    day: "",
    title: "",
    hotel: "",
    agendas: [""],
    photo: "",
    destination: "",
    experience: "",
    aboutDay: "",
    trip_highlight: false,
  });

  useEffect(() => {
    if (data?.itinerary_id) {
      getIitineraryData();
    } else {
      clearInput();
    }
    console.log(data, "data");
  }, [data]);

  const getIitineraryData = () => {
    setLoading(true);

    fetchData(`api/itinerary/${data?.itinerary_id}`).then((res) => {
      setLoading(false);
      if (res.success) {
        const data = res;

        setFormState({
          day: data.day_num || "",
          title: data.title || "",
          hotel: data.hotel || "",
          agendas: data.agenda || "",
          photo: data.default_image || "",
          destination: data.destination || "",
          experience: data?.experience, // Assuming experience is not in data, set default value
          aboutDay: data.about_this_day || "",
          trip_highlight: data.trip_highlight || "",
        });

        console.log(formState, "form state");
        setPictures(res?.images);
        // setModalItineraryData(res.data);
      } else {
        // setModalItineraryData([]);
        toast.error(res.message);
      }
    });
  };

  console.log(formState, "form state");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearInput = () => {
    setFormState({
      day: "",
      title: "",
      hotel: "",
      agendas: [""],
      photo: "",
      destination: "",
      experience: "",
      aboutDay: "",
      trip_highlight: false,
    });
    setPictures([]);
    setRemovedImages([]);
  };

  const rules = {
    day: {
      required: true,
      type: "number",
      min: 1, // Ensures that the day number is valid
      max: 31, // Assuming it's related to a date or a day count
    },
    title: {
      required: true,
      maxLength: 100, // Limits title length
    },
    hotel: {
      required: true,
      maxLength: 100, // Limits hotel name length
    },
    agendas: {
      required: true,
      maxLength: 500, // Limits agenda text length
    },
    // photo: {
    //   required: false,
    //   type: "image", // Ensures the file is a valid image type (jpg, png, gif)
    // },
    destination: {
      required: true,
      maxLength: 100, // Limits destination name length
    },
    experience: {
      required: true,
      maxLength: 500, // Limits experience text length
    },
    aboutDay: {
      required: true,
      maxLength: 500, // Limits "about this day" text length
    },
  };

  const onSubmit = () => {
    const { isValid, errors } = validateFields(formState, rules);

    if (!isValid) {
      return;
    } else {
      if (data?.itinerary_id) {
        toast.loading("Editing Itinerary....");
      } else {
        toast.loading("Adding Itinerary....");
      }
    }

    setSending(true);

    const body = {
      day_num: formState?.day,
      title: formState?.title,
      hotel: formState?.hotel,
      agenda: formState?.agendas,
      destination: formState?.destination,
      experience: formState?.experience,
      edition: data?.edition_id,
      about_this_day: formState?.aboutDay,
      trip_highlight: formState?.trip_highlight,
    };

    let uploadedPictures = pictures.filter((item) => item.path);
    if (uploadedPictures.length) {
      body.images = uploadedPictures;
    }

    if (uploadedPictures.length) {
      body.default_image = uploadedPictures[0];
    }

    if (removedImages.length) {
      body.delete_images = removedImages;
    }

    console.log(body, "body");

    let api = data?.itinerary_id
      ? `api/itinerary/${data?.itinerary_id}/`
      : "api/itinerary/";
    let method = data?.itinerary_id ? "put" : "post";

    fetchData(api, method, body, "", true).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        toast.success("Success");
        onHide();
        clearInput();
      } else {
        toast.error(res.message);
      }
    });
  };

  // Initialize state with one empty agenda
  // const [agendas, setAgendas] = useState([""]);

  // Handle change in text area
  const handleAChange = (index, event) => {
    const newAgendas = [...formState.agendas];
    newAgendas[index] = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      agendas: newAgendas,
    }));
  };

  // Add a new text area
  const handleAdd = () => {
    setFormState((prevState) => ({
      ...prevState,
      agendas: [...formState?.agendas, ""],
    }));
  };

  // Remove a text area
  const handleRemove = (index) => {
    if (formState.agendas.length > 1) {
      const newAgendas = formState.agendas.filter((_, i) => i !== index);
      setFormState((prevState) => ({
        ...prevState,
        agendas: newAgendas,
      }));
    }
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">Add Itinerary</h3>
      </Modal.Header>
      <div>
        <div
          className="modal-body pb-7 pt-5 px-lg-10"
          style={
            loading
              ? {
                  filter: "blur(5px)",
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <div
            className="scroll-y pe-7 ps-3 h-500px"
            id="kt_modal_add_customer_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_customer_header"
            data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="row g-5">
              <div className="col-md-4 fv-row">
                <label htmlFor="day" className="fs-6 fw-bold mb-2">
                  Pick a Day <small>(Numbers Only)</small>
                </label>
                <input
                  type="number"
                  name="day"
                  className="form-control fs-7"
                  value={formState.day}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4 fv-row">
                <label htmlFor="title" className="fs-6 fw-bold mb-2">
                  Title of the Day
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control fs-7"
                  value={formState.title}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4 fv-row">
                <label htmlFor="hotel" className="fs-6 fw-bold mb-2">
                  Add Hotel
                </label>
                <Select
                  showSearch
                  name="hotel"
                  value={formState.hotel}
                  onChange={(value) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      hotel: value,
                    }));
                  }}
                  placeholder="Choose Option"
                  className="fs-7 w-100"
                  style={{ width: "100%" }}
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                  options={hotelList}
                ></Select>
              </div>
              <div>
                {Array.isArray(formState?.agendas) &&
                  formState?.agendas.map((agenda, index) => (
                    <div className="row mb-2" key={index}>
                      <div className="col-md-11">
                        <div className="col-md-12 fv-row">
                          <label
                            htmlFor={`agenda-${index}`}
                            className="fs-6 fw-bold mb-2"
                          >
                            Agenda Point {index + 1}
                          </label>
                          <textarea
                            name={`agenda-${index}`}
                            className="form-control fs-7"
                            id={`agenda-${index}`}
                            cols={30}
                            rows={2}
                            value={agenda}
                            onChange={(e) => handleAChange(index, e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 d-flex align-items-center mt-8">
                        {index === 0 ? (
                          <Tooltip title="Add Agendas">
                            <div
                              className="ms-2 cursor-pointer"
                              onClick={handleAdd}
                            >
                              <i className="ki-outline fs-1 ki-plus-square text-success"></i>
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Remove Agendas">
                            <div
                              className="ms-2 cursor-pointer"
                              onClick={() => handleRemove(index)}
                            >
                              <i className="ki-outline fs-1 ki-minus-square text-danger"></i>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              {/* <div className="col-md-12 fv-row">
                <label htmlFor="agenda" className="fs-6 fw-bold mb-2">
                  Agenda
                </label>
                <textarea
                  name="agenda"
                  className="form-control fs-7"
                  id="agenda"
                  cols={30}
                  rows={3}
                  value={formState.agenda}
                  onChange={handleChange}
                />
              </div> */}

              <div className="col-md-12 fv-row">
                <div>
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Images
                  </label>
                  <Dropzone
                    pictures={pictures}
                    setPictures={setPictures}
                    removedImages={removedImages}
                    setRemovedImages={setRemovedImages}
                  />
                </div>
              </div>

              {/* <div className="col-md-6 fv-row">
                <label htmlFor="photo" className="fs-6 fw-bold mb-2">
                  Upload Photo
                </label>
                {imagePreview ? (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      src={imagePreview}
                      alt="Profile Preview"
                      style={{
                        width: "150px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: "8px",
                        border: "2px solid #ccc",
                      }}
                    />
                    <i
                      title="Remove Image"
                      className="ki-outline ki-cross fs-2"
                      onClick={() => handleRemoveImages()}
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        cursor: "pointer",
                        zIndex: 2,
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                ) : (
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    className="form-control fs-7"
                    onChange={(e) => handleImagesChange(e)}
                  />
                )}
              </div> */}

              <div className="col-md-6 fv-row">
                <label htmlFor="destination" className="fs-6 fw-bold mb-2">
                  Add Destinations
                </label>

                <Select
                  showSearch
                  name="destination"
                  value={formState.destination}
                  onChange={(value) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      destination: value,
                    }));
                  }}
                  placeholder="Choose Option"
                  className="fs-7 w-100"
                  style={{ width: "100%" }}
                  dropdownStyle={{ zIndex: 1200 }}
                  size="large"
                  options={destinationList}
                ></Select>
              </div>

              <div className="col-md-6 fv-row ">
                <label htmlFor="experience" className="fs-6 fw-bold mb-2">
                  Add Experience to Itinerary
                </label>
                <input
                  type="text"
                  name="experience"
                  className="form-control fs-7"
                  value={formState.experience}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6 fv-row">
                <label htmlFor="aboutDay" className="fs-6 fw-bold mb-2">
                  About this day <small>(One Line)</small>
                </label>
                <input
                  type="text"
                  name="aboutDay"
                  className="form-control fs-7"
                  value={formState.aboutDay}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6 fv-row">
                <label htmlFor="experience" className="fs-6 fw-bold mb-3 mt-2">
                  Trip HighLight
                </label>

                <div className="form-check">
                  <input
                    type="checkbox"
                    id="trip_highlight"
                    name="trip_highlight"
                    checked={formState.trip_highlight === true}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormState({
                        ...formState,
                        trip_highlight: isChecked ? true : false,
                      });
                    }}
                    className="form-check-input"
                  />
                  <label
                    htmlFor="trip_highlight"
                    className="form-check-label fs-7"
                  >
                    Yes
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-light fs-7" onClick={onHide}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={() => onSubmit()} // Handle form submission here
          >
            {data?.itinerary_id ? "Edit Itinerary" : "Add Itinerary"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
