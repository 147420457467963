import React from "react";
import { Link } from "react-router-dom";
import { routesList } from "../../../config";

export default function RoomManager() {
  return (
    <div>
      {/*begin::Followers toolbar*/}
      <div className="d-flex flex-wrap flex-stack  mb-6 ">
        {/*begin::Title*/}
        <ul className="nav nav-tabs nav-pills  flex-row border-0 flex-md-row me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px">
          <li className="nav-item me-0 mb-md-0 me-4">
            <a
              className="nav-link px-10 bg-white active btn btn-flex btn-active-primary btn-color-gray-800 btn-active-color-white"
              data-bs-toggle="tab"
              href="#kt_vtab_pane_4"
            >
              <span className="d-flex flex-column align-items-start">
                <span className="fs-4 fw-bold">Overview</span>
                {/* <span class="fs-7">Total calls to be done - <b>3</b></span> */}
              </span>
            </a>
          </li>
          <li className="nav-item me-0 mb-md-2">
            <a
              className="nav-link px-10  bg-white btn btn-flex btn-active-primary btn-color-gray-800 btn-active-color-white"
              data-bs-toggle="tab"
              href="#kt_vtab_pane_5"
            >
              <span className="d-flex flex-column align-items-start">
                <span className="fs-4 fw-bold">Room Manager</span>
                {/* <span class="fs-7">User onboarded <b>1733</b></span> */}
              </span>
            </a>
          </li>
        </ul>
        {/*end::Title*/}
        {/*begin::Controls*/}
        <div className="d-flex my-0 gap-4">
          {/*begin::Select*/}
          <select
            name="status"
            data-control="select2"
            data-hide-search="true"
            className="form-select fs-7 min-w-200px"
            data-placeholder="Pick an Edition"
          >
            <option value />
            <option value="Active">BHX Spain 2</option>
          </select>
          {/*end::Select*/}
        </div>
        {/*end::Controls*/}
      </div>
      {/*end::Followers toolbar*/}
      {/*begin:::Tab content*/}
      <div className="tab-content" id="myTabContent">
        {/*begin:::Tab pane*/}
        <div
          className="tab-pane fade show active"
          id="kt_vtab_pane_4"
          role="tabpanel"
        >
          {/*begin::Card*/}
          <div className="card card-flush mb-6 mb-xl-6">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Card title*/}
              <div className="card-title flex-column">
                <h2 className="mb-1">Overview</h2>
              </div>
              {/*end::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body p-9 pt-2">
              <div className=" row g-5 gx-10">
                <div className="col-md-4">
                  <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                    <div className="d-flex align-items-center me-5">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-normal text-hover-primary fs-6"
                        >
                          Median Age
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bold fs-4 me-3">
                        31
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                    <div className="d-flex align-items-center me-5">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-normal text-hover-primary fs-6"
                        >
                          Total Paid
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bold fs-4 me-3">
                        19
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                    <div className="d-flex align-items-center me-5">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-normal text-hover-primary fs-6"
                        >
                          Total Full Paid
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bold fs-4 me-3">0</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                    <div className="d-flex align-items-center me-5">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <i className="bi bi-gender-female text-gray-900 fs-3" />
                      </div>
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-normal text-hover-primary fs-6"
                        >
                          Female
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bold fs-4 me-3">
                        10
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                    <div className="d-flex align-items-center me-5">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <i className="bi bi-gender-male text-gray-900 fs-3" />
                      </div>
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-normal text-hover-primary fs-6"
                        >
                          Males
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bold fs-4 me-3">9</span>
                    </div>
                  </div>
                  <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                    <div className="d-flex align-items-center me-5">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <i className="bi bi-gender-neuter text-gray-900 fs-3" />
                      </div>
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-normal text-hover-primary fs-6"
                        >
                          NB
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="text-gray-800 fw-bold fs-4 me-3">0</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <a className="fw-bold fs-6" href>
                      Preferences not filled by 1 users
                    </a>
                  </div>
                  <div>
                    <a className="fw-bold fs-6" href>
                      Visa Slip not filled by 3 users
                    </a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="separator separator-dashed my-3" />
                </div>
                <div className="col-md-4 ">
                  <h3 className="fw-bold mb-6">Food Count</h3>
                  <div className="m-0">
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Veg Only
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            1 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Vegan
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            1 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Jain
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            1 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Chicken
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            0 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Mutton
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            1 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Eggs
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            3 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Everything
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            1 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <h3 className="fw-bold mb-6">Drinking Preference</h3>
                  <div className="m-0">
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            I don't drink at all{" "}
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            0 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            I drink occasionally it the vibe is right
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            2 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            I like drinking
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            2 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            I love drinking
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            0 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <h3 className="fw-bold mb-6">
                    Primary Agenda (Resident Preference)
                  </h3>
                  <div className="m-0">
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Go with the flow, be in present{" "}
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            0 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Be out and about, explore everything
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            1 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <a
                            href="#"
                            className="text-gray-700 fw-semibold text-hover-primary fs-6"
                          >
                            Chill, Party &amp; Disconnect from life{" "}
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                            1 Residents
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-6 mb-xl-9">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Card title*/}
              <div className="card-title flex-column">
                <h2 className="mb-1">Room Overview</h2>
              </div>
              {/*end::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body p-9 pt-2">
              <div className=" row g-5 gx-10">
                <div className="col-md-4 ">
                  <h3 className="fw-bold mb-6">
                    Room - 17 Residents{" "}
                    <a
                      className="ms-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                      href
                    >
                      <i className="ki-outline ki-pencil text-primary fs-4" />
                    </a>
                  </h3>
                  <div className="m-0"></div>
                </div>
                <div className="col-md-4 ">
                  <h3 className="fw-bold mb-6">
                    Room 6 - 1 Residents{" "}
                    <a
                      className="ms-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                      href
                    >
                      <i className="ki-outline ki-pencil text-primary fs-4" />
                    </a>
                  </h3>
                  <div className="m-0">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                        <img src="/media/blank.png" alt="" />
                      </div>
                      <div className="d-flex flex-column">
                        <a href className="text-gray-800 fs-6 mb-0 fw-bolder">
                          Kaushik Kumbhani
                        </a>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                        <img src="/media/blank.png" alt="" />
                      </div>
                      <div className="d-flex flex-column">
                        <a href className="text-gray-800 fs-6 mb-0 fw-bolder">
                          Jay Ahya
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <h3 className="fw-bold mb-6">
                    Room 20 - 1 Residents{" "}
                    <a
                      className="ms-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                      href
                    >
                      <i className="ki-outline ki-pencil text-primary fs-4" />
                    </a>
                  </h3>
                  <div className="m-0">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                        <img src="/media/blank.png" alt="" />
                      </div>
                      <div className="d-flex flex-column">
                        <a href className="text-gray-800 fs-6 mb-0 fw-bolder">
                          Sanjay Reddy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end:::Tab pane*/}
        {/*begin:::Tab pane*/}
        <div className="tab-pane fade " id="kt_vtab_pane_5" role="tabpanel">
          <div className="d-flex flex-wrap flex-stack mb-6 ">
            {/*begin::Title*/}
            <h3 className="text-gray-800 fw-bold my-0">
              Room Manager /
              <span className="fs-6 text-primary fw-semibold ms-1">
                Showing 9 users
              </span>
            </h3>
            {/*end::Title*/}
            {/*begin::Controls*/}
            <div className="d-flex my-0 gap-4">
              {/*begin::Select*/}
              <div className="position-relative">
                <select
                  name="status"
                  data-control="select2"
                  data-hide-search="true"
                  className="form-select fs-7 min-w-200px"
                  data-placeholder="Select Gender"
                >
                  <option value />
                  <option value={1}>Male</option>
                  <option value={2}>Female</option>
                  <option value={3}>Trans</option>
                  <option value={3}>Prefer not to say</option>
                </select>
              </div>
              {/*end::Select*/}
            </div>
            {/*end::Controls*/}
          </div>
          <div className="card card-flush mb-6 mb-xl-6">
            {/*begin::Card body*/}
            <div className="card-body p-4 pt-2">
              {/*begin::Table container*/}
              <div className="table-responsive tbl-sales">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                  id
                >
                  <thead>
                    <tr className="fw-bolder text-gray-900 bg-white">
                      <th className="min-w-250px">Name</th>
                      <th className="min-w-80px">Score</th>
                      <th className="min-w-75px">Age</th>
                      <th className="min-w-75px">Designation</th>
                      <th className="min-w-100px">Food</th>
                      <th className="min-w-85px">Drinking</th>
                      <th className="min-w-100px">Smoking</th>
                      <th className="min-w-100px">Sleep</th>
                      <th className="min-w-100px text-end">Room</th>
                    </tr>
                  </thead>
                  <tbody className=" fw-semibold fs-7">
                    <tr className>
                      <td className="bdr-left-user">
                        <div className="d-flex  align-items-center ps-3">
                          <div className="d-flex flex-column">
                            <Link
                              to={routesList["customer_details"].path}
                              className="text-primary mb-1 fw-bolder fs-5"
                            >
                              Sahithi Reddy
                            </Link>
                            <span className="text-gray-600">
                              Check-in Time: Oct 2, 2023 2:00 pm
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>4</td>
                      <td>29</td>
                      <td>Business Owner</td>
                      <td>&lt; 3 Non Veg</td>
                      <td>I drink ocassionally</td>
                      <td>I smoke ocassionally</td>
                      <td>A Night Watchman</td>
                      <td>
                        <div className="d-flex flex-shrink-0 justify-content-end align-items-center">
                          <i className="ki-outline ki-notification-2 fs-1 text-gray-800" />
                          <a
                            href="#"
                            className="btn btn-icon bg-white btn-active-color-primary btn-sm ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_add_customer"
                          >
                            <i className="ki-outline ki-pencil fs-2 text-info" />
                          </a>
                          {/* <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_download" class="btn btn-icon bg-white btn-active-color-primary btn-sm">
                                                                          <i class="ki-outline ki-file-down fs-2 text-gray-900"></i>
                                                                      </a> */}
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_download"
                            className="btn p-0 btn-icon  btn-active-color-primary btn-sm"
                          >
                            <img
                              src="/media/csv.png"
                              className="h-20px"
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              <div className=" rounded p-4 bg-light">
                <div className>
                  <div className="d-flex flex-stack">
                    <div>
                      <div className>
                        <div className="fw-semibold text-gray-800 fs-7">
                          {" "}
                          Special Request:{" "}
                          <span className="fw-bolder">None</span>
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          {" "}
                          Primary Agenda: <span className="fw-bolder">-</span>
                        </div>
                      </div>
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      Coming for sure
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">Pune</div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      0-500000
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      S2: Review
                    </div>
                    <div className="d-flex flex-shrink-0 justify-content-end">
                      <div className="min-w-250px  text-end me-5">
                        <div className="fs-7 text-gray-900 fw-bold">
                          <span className="me-4">See Contact</span>{" "}
                          <span>Passport Details</span>
                        </div>
                        <div className="fs-7 text-gray-600">
                          Remind to Share Preference
                        </div>
                      </div>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="lab la-linkedin fs-1 text-gray-800" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-instagram fs-2 text-danger" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-address-book fs-2 text-primary" />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_2"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-airplane fs-2 text-success" />
                      </a>
                      <a
                        id="kt_drawer_example_toggle"
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-0"
                      >
                        <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-6 mb-xl-6">
            {/*begin::Card body*/}
            <div className="card-body p-4 pt-2">
              {/*begin::Table container*/}
              <div className="table-responsive tbl-sales">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                  id
                >
                  <thead>
                    <tr className="fw-bolder text-gray-900 bg-white">
                      <th className="min-w-250px">Name</th>
                      <th className="min-w-80px">Score</th>
                      <th className="min-w-75px">Age</th>
                      <th className="min-w-75px">Designation</th>
                      <th className="min-w-100px">Food</th>
                      <th className="min-w-85px">Drinking</th>
                      <th className="min-w-100px">Smoking</th>
                      <th className="min-w-100px">Sleep</th>
                      <th className="min-w-100px text-end">Room</th>
                    </tr>
                  </thead>
                  <tbody className=" fw-semibold fs-7">
                    <tr className>
                      <td className="bdr-left-user">
                        <div className="d-flex  align-items-center ps-3">
                          <div className="d-flex flex-column">
                            <Link
                              to={routesList["customer_details"].path}
                              className="text-primary mb-1 fw-bolder fs-5"
                            >
                              Sahithi Reddy
                            </Link>
                            <span className="text-gray-600">
                              Check-in Time: Oct 2, 2023 2:00 pm
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>4</td>
                      <td>29</td>
                      <td>Business Owner</td>
                      <td>&lt; 3 Non Veg</td>
                      <td>I drink ocassionally</td>
                      <td>I smoke ocassionally</td>
                      <td>A Night Watchman</td>
                      <td>
                        <div className="d-flex flex-shrink-0 justify-content-end align-items-center">
                          <i className="ki-outline ki-notification-2 fs-1 text-gray-800" />
                          <a
                            href="#"
                            className="btn btn-icon bg-white btn-active-color-primary btn-sm ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_add_customer"
                          >
                            <i className="ki-outline ki-pencil fs-2 text-info" />
                          </a>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_download"
                            className="btn p-0 btn-icon  btn-active-color-primary btn-sm"
                          >
                            <img
                              src="/media/csv.png"
                              className="h-20px"
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              <div className=" rounded p-4 bg-light">
                <div className>
                  <div className="d-flex flex-stack">
                    <div>
                      <div className>
                        <div className="fw-semibold text-gray-800 fs-7">
                          {" "}
                          Special Request:{" "}
                          <span className="fw-bolder">None</span>
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          {" "}
                          Primary Agenda: <span className="fw-bolder">-</span>
                        </div>
                      </div>
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      Coming for sure
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">Pune</div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      0-500000
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      S2: Review
                    </div>
                    <div className="d-flex flex-shrink-0 justify-content-end">
                      <div className="min-w-250px  text-end me-5">
                        <div className="fs-7 text-gray-900 fw-bold">
                          <span className="me-4">See Contact</span>{" "}
                          <span>Passport Details</span>
                        </div>
                        <div className="fs-7 text-gray-600">
                          Remind to Share Preference
                        </div>
                      </div>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="lab la-linkedin fs-1 text-gray-800" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-instagram fs-2 text-danger" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-address-book fs-2 text-primary" />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_2"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-airplane fs-2 text-success" />
                      </a>
                      <a
                        id="kt_drawer_example_toggle"
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-0"
                      >
                        <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-6 mb-xl-6">
            {/*begin::Card body*/}
            <div className="card-body p-4 pt-2">
              {/*begin::Table container*/}
              <div className="table-responsive tbl-sales">
                {/*begin::Table*/}
                <table
                  className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                  id
                >
                  <thead>
                    <tr className="fw-bolder text-gray-900 bg-white">
                      <th className="min-w-250px">Name</th>
                      <th className="min-w-80px">Score</th>
                      <th className="min-w-75px">Age</th>
                      <th className="min-w-75px">Designation</th>
                      <th className="min-w-100px">Food</th>
                      <th className="min-w-85px">Drinking</th>
                      <th className="min-w-100px">Smoking</th>
                      <th className="min-w-100px">Sleep</th>
                      <th className="min-w-100px text-end">Room</th>
                    </tr>
                  </thead>
                  <tbody className=" fw-semibold fs-7">
                    <tr className>
                      <td className="bdr-left-user">
                        <div className="d-flex  align-items-center ps-3">
                          <div className="d-flex flex-column">
                            <Link
                              to={routesList["customer_details"].path}
                              className="text-primary mb-1 fw-bolder fs-5"
                            >
                              Sahithi Reddy
                            </Link>
                            <span className="text-gray-600">
                              Check-in Time: Oct 2, 2023 2:00 pm
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>4</td>
                      <td>29</td>
                      <td>Business Owner</td>
                      <td>&lt; 3 Non Veg</td>
                      <td>I drink ocassionally</td>
                      <td>I smoke ocassionally</td>
                      <td>A Night Watchman</td>
                      <td>
                        <div className="d-flex flex-shrink-0 justify-content-end align-items-center">
                          <i className="ki-outline ki-notification-2 fs-1 text-gray-800" />
                          <a
                            href="#"
                            className="btn btn-icon bg-white btn-active-color-primary btn-sm ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_add_customer"
                          >
                            <i className="ki-outline ki-pencil fs-2 text-info" />
                          </a>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_download"
                            className="btn p-0 btn-icon  btn-active-color-primary btn-sm"
                          >
                            <img
                              src="/media/csv.png"
                              className="h-20px"
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Table container*/}
              <div className=" rounded p-4 bg-light">
                <div className>
                  <div className="d-flex flex-stack">
                    <div>
                      <div className>
                        <div className="fw-semibold text-gray-800 fs-7">
                          {" "}
                          Special Request:{" "}
                          <span className="fw-bolder">None</span>
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          {" "}
                          Primary Agenda: <span className="fw-bolder">-</span>
                        </div>
                      </div>
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      Coming for sure
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">Pune</div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      0-500000
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      S2: Review
                    </div>
                    <div className="d-flex flex-shrink-0 justify-content-end">
                      <div className="min-w-250px  text-end me-5">
                        <div className="fs-7 text-gray-900 fw-bold">
                          <span className="me-4">See Contact</span>{" "}
                          <span>Passport Details</span>
                        </div>
                        <div className="fs-7 text-gray-600">
                          Remind to Share Preference
                        </div>
                      </div>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="lab la-linkedin fs-1 text-gray-800" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-instagram fs-2 text-danger" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-address-book fs-2 text-primary" />
                      </a>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_2"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                      >
                        <i className="ki-outline ki-airplane fs-2 text-success" />
                      </a>
                      <a
                        id="kt_drawer_example_toggle"
                        href="#"
                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-0"
                      >
                        <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end:::Tab pane*/}
      </div>
      {/*begin::Row*/}
    </div>
  );
}
