import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { validateFields } from "../../../../components/validation";

export default function EmployeeModal({ show, onHide, data, userRoles }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [userData, setUserData] = useState({
    last_name: "",
    first_name: "",
    email: "",
    contact_no: "",
    role: "",
  });

  const validationRules = {
    first_name: { required: true, maxLength: 50 },
    // last_name: { required: true, maxLength: 50 },
    email: { required: true, type: "email" },
    contact_no: { required: true, maxLength: 15 },
    role: { required: true },
  };

  useEffect(() => {
    if (data?.uuid) {
      console.log(data?.uuid, "user_data");
      setUserId(data?.uuid);
      setUserData({
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        contact_no: data?.phone,
        role: data?.user_type,
      });

      setImage(data?.photo);
      setImagePreview(data?.photo);
    }
  }, [data]);

  const onSubmitUser = async () => {
    const { isValid } = validateFields(userData, validationRules);

    if (!isValid) {
      return;
    }

    setSending(true);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const body = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      user_type: userData.role,
      ...(image && isURL(image) ? {} : { photo: image || "" }),
    };

    fetchData(`api/accounts/${userId}/`, "put", body, "", true).then((res) => {
      setSending(false);
      if (res.success) {
        console.log(res);
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setUserDataValue = (key, value) => {
    const common_data = {
      ...userData,
      [key]: value,
    };
    setUserData(common_data);
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setImage(file);

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  console.log(imagePreview, "imagePreview");

  const handleRemoveImage = () => {
    setImage(null);
    setImagePreview(null);
  };

  return (
    <Modal
      title="Edit Employee"
      open={show}
      onCancel={onHide}
      footer={[
        <Button key="back" onClick={onHide}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmitUser}
          loading={sending}
        >
          Submit
        </Button>,
      ]}
      centered
      destroyOnClose
    >
      <div style={{ minHeight: "350px" }}>
        <div className="row mb-6 align-items-center">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Profile Image
          </label>

          <div className="col-lg-8">
            <div
              className="image-input image-input-outline"
              data-kt-image-input="true"
              style={{
                backgroundImage: `url(${imagePreview || "/media/blank.png"})`,
              }}
            >
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{
                  backgroundImage: `url(${imagePreview || "/media/blank.png"})`,
                }}
              />

              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title="Change avatar"
              >
                <i className="ki-outline ki-pencil fs-7" />

                <input
                  type="file"
                  name="avatar"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageChange}
                />
                <input type="hidden" name="avatar_remove" />
              </label>

              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="cancel"
                data-bs-toggle="tooltip"
                title="Cancel avatar"
              >
                <i className="ki-outline ki-cross fs-2" />{" "}
              </span>

              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="remove"
                data-bs-toggle="tooltip"
                title="Remove avatar"
                onClick={handleRemoveImage}
              >
                <i className="ki-outline ki-cross fs-2" />{" "}
              </span>
            </div>

            <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
          </div>
        </div>

        <div className="row g-9 mb-5 align-items-center">
          <div className="col-md-6 fv-row">
            <label className="fs-6 fw-bold mb-2 required">First Name</label>
            <Input
              value={userData?.first_name}
              onChange={(e) => {
                setUserDataValue("first_name", e.target.value);
              }}
              size="large"
            />
          </div>
          <div className="col-md-6 fv-row">
            <label className="fs-6 fw-bold mb-2 required">Last Name</label>
            <Input
              value={userData?.last_name || ""}
              placeholder={userData?.last_name === "" ? "-" : ""}
              onChange={(e) => {
                setUserDataValue("last_name", e.target.value);
              }}
              size="large"
            />
          </div>
        </div>

        <div className="row g-9 mb-5 align-items-center">
          <div className="col-md-6 fv-row">
            <label className="fs-6 fw-bold mb-2 required">Email</label>
            <Input
              value={userData?.email}
              onChange={(e) => {
                setUserDataValue("email", e.target.value);
              }}
              size="large"
              disabled={!!userId}
            />
          </div>
          <div className="col-md-6 fv-row">
            <label className="fs-6 fw-bold mb-2 required">Contact No</label>
            <Input
              value={userData?.contact_no}
              onChange={(e) => {
                setUserDataValue("contact_no", e.target.value);
              }}
              size="large"
              disabled={!!userId}
            />
          </div>
        </div>

        <div className="row g-9 mb-5 align-items-center">
          <div className="col-12">
            <label className="fs-6 fw-bold required">Role</label>
          </div>
          <div className="col-12 fv-row mt-2">
            <Select
              value={userData?.role}
              onChange={(value) => {
                setUserDataValue("role", value);
              }}
              style={{ width: "100%" }}
              placeholder="Select a role"
              options={userRoles.map((e) => ({
                label: e.display_name,
                value: e.value,
              }))}
              allowClear
              size="large"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
