import { decodeValue } from "../redux/slice";

let devMode = false;

const ROOT_DOMAIN = devMode ? "43.205.147.38" : "dev-api.theexperience.co";

export const API_URL = `https://${ROOT_DOMAIN}/`;
// export const SOCKET_URL = `wss://${ROOT_DOMAIN}/ws/taprocket/customer/`;

const getHeaders = async (
  method = "get",
  body = {},
  token_ = "",
  isMultipart = false,
  signal = null
) => {
  let token = token_ || localStorage.getItem("token");

  // Decode token only if it's present
  if (token) {
    try {
      token = decodeValue(token);
    } catch (err) {
      console.error("Error decoding token:", err);
      token = ""; // Fallback in case of an error
    }
  }

  let headers = {};
  if (isMultipart) {
    headers = {
      method: method,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    let formData = new FormData();
    // console.log(Object.entries(body));
    Object.keys(body).map((item, i) => {
      if (item === "images") {
        Object.values(body)[i].map((image) => {
          formData.append(item, image);
        });
      } else if (item === "delete_images" && body[item]?.length) {
        body[item].map((item) => {
          formData.append("delete_images", item);
        });

        // For Array
      } else if (Array.isArray(body[item])) {
        body[item].forEach((element) => {
          formData.append(item, element); // Append each element directly
        });
        // body[item].forEach((element, index) => {
        //   formData.append(`${item}[${index}]`, element);
        // });

        // For Object
      } else if (typeof body[item] === "object" && body[item] !== null) {
        formData.append(item, body[item]);
      } else {
        formData.append(item, Object.values(body)[i]);
      }
    });
    headers.body = formData;
  } else {
    headers = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (signal) {
      headers.signal = signal;
    }
    if (token || token_) {
      headers.headers.Authorization = `Token ${token_ ? token_ : token}`;
    }

    if (method !== "get" && method !== "delete" && body !== null) {
      headers.body = JSON.stringify(body);
    }
  }

  // console.log(headers);

  return headers;
};

export const fetchData = async (
  apiName,
  method = "get",
  data = {},
  token = "",
  isMultipart = false,
  signal = null
) => {
  const object = await getHeaders(method, data, token, isMultipart, signal);

  let api = apiName.includes("http") ? apiName : `${API_URL}${apiName}`;

  // console.log("00000000000000000000000000000000000000000000000000");
  // console.log(api);
  // console.log(object);

  return fetch(api, object)
    .then((res) => {
      const statusCode = res.status;

      // If status code is 401, redirect to login page
      if (statusCode === 401) {
        // window.location.href = "/login"; // Redirect to login page
        localStorage.clear();
        window.location.assign("/login"); // Redirect to login page without storing history

        return {
          success: false,
          message: "Unauthorized, redirecting to login.",
        };
      }

      return res.json().then((data) => {
        return {
          success: statusCode === 200 || statusCode === 201 ? true : false,
          ...data,
        };
      });
    })
    .catch((err) => {
      return err;
    });
};
