import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LIMITS, routesList } from "../../../config";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Loader, Pagination } from "../../../components";
import { Select } from "antd";

const data1 = [
  {
    id: 1,
    name: "John Doe",
    position: "Founder & CEO at lorem LLC, CA",
    description:
      "A young and ambitious entrepreneur in the anime streetwear industry, driven by creativity and a passion for bringing unique fashion to the forefront. Ready to make a splash in the world of fashion and bring my fresh ideas to the table.",
    experience:
      "My experience, network and knowledge from 4 years in ecom space.",
    helpWith: "Help with branding and marketing ideas",
    age: 32,
    instagramLink: "#",
    linkedinLink: "#",
  },
  {
    id: 2,
    name: "John Doe",
    position: "Founder & CEO at lorem LLC, CA",
    description:
      "A young and ambitious entrepreneur in the anime streetwear industry, driven by creativity and a passion for bringing unique fashion to the forefront. Ready to make a splash in the world of fashion and bring my fresh ideas to the table.",
    experience:
      "My experience, network and knowledge from 4 years in ecom space.",
    helpWith: "Help with branding and marketing ideas",
    age: 32,
    instagramLink: "#",
    linkedinLink: "#",
  },
  {
    id: 3,
    name: "John Doe",
    position: "Founder & CEO at lorem LLC, CA",
    description:
      "A young and ambitious entrepreneur in the anime streetwear industry, driven by creativity and a passion for bringing unique fashion to the forefront. Ready to make a splash in the world of fashion and bring my fresh ideas to the table.",
    experience:
      "My experience, network and knowledge from 4 years in ecom space.",
    helpWith: "Help with branding and marketing ideas",
    age: 32,
    instagramLink: "#",
    linkedinLink: "#",
  },
];

export default function BookingsPage() {
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    status: null,
    edition: null,
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getEditions();
    getStatus();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, debouncedSearchTerm, filterData]);

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    fetchData(
      `api/booking/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&search=${encodeURIComponent(debouncedSearchTerm || "")}&status=${
        filterData?.status || ""
      }&edition=${filterData?.edition || ""}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div class="position-relative ">
                  <i class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>

                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search customer here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="position-relative ">
                  <Select
                    value={filterData?.status}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setFilterDataValue("status", value)}
                    placeholder="Select Status"
                    style={{ width: "200px" }}
                    options={statusList.map((h) => ({
                      label: h.display_name,
                      value: h.value,
                    }))}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("status", null);
                    }}
                    size="large"
                  />
                </div>
                <div className="position-relative ">
                  <Select
                    value={filterData?.edition}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setFilterDataValue("edition", value)}
                    placeholder="Select Edition"
                    style={{ width: "200px" }}
                    options={editionsList}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("edition", null);
                    }}
                    size="large"
                  />
                </div>
              </div>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}
                        <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-gray-900 bg-light-primary">
                              <th className="min-w-205px">Details</th>
                              <th className="min-w-125px">Edition</th>
                              <th className="min-w-275px">Top Priorities</th>
                              <th className="min-w-175px">Growth Areas</th>
                              <th className="min-w-150px">Status</th>
                              <th className="min-w-75px">Age</th>
                              <th className="min-w-175px">Intentions</th>
                              <th className="min-w-125px">Explore more</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold fs-7">
                            {data.map((item) => {
                              const { user } = item;
                              const age =
                                new Date().getFullYear() -
                                new Date(user.date_of_birth).getFullYear(); // calculate age
                              return (
                                <tr key={item.id}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                        <img
                                          src={user.photo || "/media/blank.png"}
                                          alt="user"
                                        />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <Link
                                          to={`${routesList[
                                            "bookings_detail"
                                          ].path.replace(":id", item.id)}`}
                                          className="text-primary text-hover-primary mb-1 fw-bolder fs-5 min-w-200px"
                                        >
                                          {user.first_name} {user.last_name}
                                        </Link>
                                        <div className="text-gray-600">
                                          {user.user_work[0]?.designation ||
                                            "N/A"}
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td>{item.edition_name}</td>
                                  <td>
                                    {item.top_priority_labels.length > 0 ? (
                                      <ul className="list-unstyled mt-5">
                                        {item.top_priority_labels
                                          .slice(0, 2)
                                          .map((priority, index) => (
                                            <li key={index}>{priority}</li>
                                          ))}
                                        {item.top_priority_labels.length >
                                          2 && <li>...</li>}
                                      </ul>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>

                                  <td>
                                    {item.growth_areas_labels.length > 0 ? (
                                      <ul className="list-unstyled">
                                        {item.growth_areas_labels
                                          .slice(0, 2)
                                          .map((area, index) => (
                                            <li key={index}>{area}</li>
                                          ))}
                                        {item.growth_areas_labels.length >
                                          2 && <li>...</li>}
                                      </ul>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>

                                  <td>
                                    <span
                                      className={`badge badge-light-${
                                        item.status == 1
                                          ? "warning"
                                          : item.status == 2
                                          ? "danger"
                                          : item?.status == 3
                                          ? "success"
                                          : "dark"
                                      }`}
                                    >
                                      {/* {item.status == 1
                                        ? "Pending"
                                        : item.status == 2
                                        ? "Abandon"
                                        : item?.status == 3
                                        ? "Approved"
                                        : "Attended"} */}
                                      {item.status_labels}
                                    </span>
                                  </td>
                                  <td>{age}</td>
                                  <td>{item.intentions}</td>
                                  <td>
                                    <div className="d-flex flex-shrink-0">
                                      <a
                                        href={user.user_profile.instagram_url}
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="lab la-instagram fs-2x text-primary" />
                                      </a>
                                      <a
                                        href={user.user_profile.linkedin_url}
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                                      >
                                        <i className="lab la-linkedin fs-2x text-primary" />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        {/*end::Table*/}
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>
    </div>
  );
}
