import React from "react";

const cardData = [
  {
    id: 1,
    rating: "4.5",
    name: "Patric Watson",
    status: "Paid on",
    edition: "BHX Bali 8",
    dueDate: "May 17, 2024",
  },
  {
    id: 2,
    rating: "4.5",
    name: "Patric Watson",
    status: "Paid on",
    edition: "BHX Bali 8",
    dueDate: "May 17, 2024",
  },
  // Add more card objects as needed
];

export default function OnboardingCalls() {
  return (
    <div>
      {/*begin::Followers toolbar*/}
      <div className="d-flex flex-wrap flex-stack  mb-6 ">
        {/*begin::Title*/}
        <ul className="nav nav-tabs nav-pills  flex-row border-0 flex-md-row me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px">
          <li className="nav-item me-0 mb-md-0 me-4">
            <a
              className="nav-link px-10 active btn btn-flex btn-active-primary btn-color-gray-800 btn-active-color-white"
              data-bs-toggle="tab"
              href="#kt_vtab_pane_4"
            >
              <span className="d-flex flex-column align-items-start">
                <span className="fs-4 fw-bold">Onboarding Calls</span>
                <span className="fs-7">
                  Total calls to be done - <b>3</b>
                </span>
              </span>
            </a>
          </li>
          <li className="nav-item me-0 mb-md-2">
            <a
              className="nav-link px-10 btn btn-flex btn-active-primary btn-color-gray-800 btn-active-color-white"
              data-bs-toggle="tab"
              href="#kt_vtab_pane_5"
            >
              <span className="d-flex flex-column align-items-start">
                <span className="fs-4 fw-bold">Calls Done</span>
                <span className="fs-7">
                  User onboarded <b>1733</b>
                </span>
              </span>
            </a>
          </li>
        </ul>
        {/*end::Title*/}
        {/*begin::Controls*/}
        <div className="d-flex my-0 gap-4">
          {/*begin::Select*/}
          <select
            name="status"
            data-control="select2"
            data-hide-search="true"
            className="form-select fs-7 min-w-150px"
            data-placeholder="Select Edition"
          >
            <option value />
            <option value="Active">Amsterdam</option>
          </select>
          {/*end::Select*/}
        </div>
        {/*end::Controls*/}
      </div>
      {/*end::Followers toolbar*/}
      {/*begin::Row*/}
      <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
        {cardData.map((card) => (
          <div className="col-md-6 col-xxl-4" key={card.id}>
            {/*begin::Card*/}
            <div className="card hover-elevate-up shadow-sm parent-hover">
              <div className="card-body p-5">
                <div className="d-flex flex-stack flex-row">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                      <div className="symbol-label fs-5 fw-semibold bg-light-primary text-primary">
                        {card.rating}
                      </div>
                    </div>
                    <div>
                      <a
                        href="#"
                        className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                      >
                        {card.name}
                      </a>
                      <div className="fw-semibold text-primary mb-0">
                        {card.status}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="separator separator-dashed border-gray-300 my-4" />
                <div>
                  <div className="row g-1">
                    <div className="col-md-6">
                      <span className="fw-semibold text-gray-800 fs-7">
                        Edition :{" "}
                        <span className="fw-bolder">{card.edition}</span>
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span className="fw-semibold text-gray-800 fs-7">
                        Due on:{" "}
                        <span className="fw-bolder">{card.dueDate}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed border-gray-300 my-4" />
                <div className="d-flex flex-stack">
                  <div className="d-flex flex-shrink-0 justify-content-end">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="ki-outline ki-airplane fs-2 text-success" />
                    </a>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_2"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="bi bi-telephone fs-2 text-info" />
                    </a>
                    <a
                      id="kt_drawer_example_toggle"
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="ki-outline ki-user fs-2 text-warning" />
                    </a>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_3"
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="fa-solid fa-venus-mars fs-4 text-warning" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                    >
                      <i className="bi bi-whatsapp fs-4 text-success" />
                    </a>
                  </div>
                </div>
              </div>
              {/*begin::Card body*/}
            </div>
            {/*end::Card*/}
          </div>
        ))}
      </div>
    </div>
  );
}
