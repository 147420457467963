import toast from "react-hot-toast";

export const validateFields = (data, rules) => {
  const errors = {};
  let isValid = true;
  let firstErrorField = null; // Track the first field with an error

  // Helper function to get validation messages
  const getValidationMessage = (type, extra) => {
    switch (type) {
      case "email":
        return "Please enter a valid email address";
      case "url":
        return "Please enter a valid URL";
      case "number":
        return `Please enter a valid number${
          extra ? ` between ${extra.min} and ${extra.max}` : ""
        }`;
      case "image":
        return "Please upload a valid image file (e.g., jpg, jpeg, png)";
      case "video":
        return "Please upload a valid video file (e.g., mp4, avi, mkv)";
      default:
        return "Invalid input";
    }
  };

  Object.keys(rules).some((key) => {
    const value = data[key];
    const rule = rules[key];

    // Required field check
    if (
      rule.required &&
      (value === undefined || value === "" || value === null)
    ) {
      const message = "is required";
      errors[key] = message;
      if (firstErrorField === null) {
        firstErrorField = key; // Update the first error field
      }
      isValid = false;
    } else {
      // Specific field checks
      if (rule.type === "email") {
        // Email validation
        if (!/\S+@\S+\.\S+/.test(value)) {
          const message = getValidationMessage("email");
          errors[key] = message;
          if (firstErrorField === null) {
            firstErrorField = key; // Update the first error field
          }
          isValid = false;
        }
      }

      // if (rule.type === "url") {
      //   // URL validation
      //   try {
      //     new URL(value);
      //   } catch (_) {
      //     const message = getValidationMessage("url");
      //     errors[key] = message;
      //     if (firstErrorField === null) {
      //       firstErrorField = key; // Update the first error field
      //     }
      //     isValid = false;
      //   }
      // }

      if (rule.type === "url") {
        // URL validation
        const urlPattern =
          /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-_.~%]*)*$/;
        if (!urlPattern.test(value)) {
          const message = getValidationMessage("url");
          errors[key] = message;
          if (firstErrorField === null) {
            firstErrorField = key; // Update the first error field
          }
          isValid = false;
        }
      }

      if (rule.type === "number") {
        // Number validation
        if (
          isNaN(value) ||
          (rule.min !== undefined && value < rule.min) ||
          (rule.max !== undefined && value > rule.max)
        ) {
          const message = getValidationMessage("number", {
            min: rule.min,
            max: rule.max,
          });
          errors[key] = message;
          if (firstErrorField === null) {
            firstErrorField = key; // Update the first error field
          }
          isValid = false;
        }
      }

      if (rule.type === "image") {
        // Image file type validation
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        if (value && !validImageTypes.includes(value.type)) {
          const message = getValidationMessage("image");
          errors[key] = message;
          if (firstErrorField === null) {
            firstErrorField = key; // Update the first error field
          }
          isValid = false;
        }
      }

      if (rule.type === "video") {
        // Video file type validation
        const validVideoTypes = ["video/mp4", "video/avi", "video/mkv"];
        if (value && !validVideoTypes.includes(value.type)) {
          const message = getValidationMessage("video");
          errors[key] = message;
          if (firstErrorField === null) {
            firstErrorField = key; // Update the first error field
          }
          isValid = false;
        }
      }

      // Maximum length validation
      if (
        rule.maxLength &&
        typeof value === "string" &&
        value.length > rule.maxLength
      ) {
        const message = `Must be at most ${rule.maxLength} characters`;
        errors[key] = message;
        if (firstErrorField === null) {
          firstErrorField = key; // Update the first error field
        }
        isValid = false;
      }
    }

    // Stop iterating once we find the first error
    return firstErrorField !== null;
  });

  // Show toast for the first error field
  if (firstErrorField) {
    const message = errors[firstErrorField];
    toast.error(
      `${
        firstErrorField.charAt(0).toUpperCase() + firstErrorField.slice(1)
      }: ${message}`
    );
  }

  return { isValid, errors };
};
